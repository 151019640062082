@import '@assets/scss/style.module.scss';

.searchOptionArea {
  margin-top: 20px;
  .flex {
    width: 50%;
    .sizeS {
      width: 160px;
    }
  }
}

.title {
  margin: 10px 0;
}

.resetIcon {
  width: 30px;
  height: 31px;
  margin: 0 10px;
  padding: 5px 6px 6.7px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #e3e3e3;
  cursor: pointer;
}

.leftControls {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .fontBlue {
    color: #00aab7;
  }
}
