@import '@assets/scss/style.module.scss';

.statusDashBoard {
  .status {
    .value {
      cursor: pointer;
    }
  }

  .delay {
    .value {
      cursor: pointer;
    }
  }

  .color_primary {
    color: $color-primary;
  }
  .color_pink {
    color: $color-pink;
  }
  .detail {
    position: unset !important;
  }
}
