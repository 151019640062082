@import '@assets/scss/style.module.scss';
.container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;

  .dimmed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }

  .layerPopup {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 60px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      vertical-align: middle;
    }
  }
}

.popup {
  max-width: 1150px;
  display: inline-block;
  position: relative;
  z-index: 1;
  min-width: 460px;
  padding: 32px;
  border-radius: 20px;
  background: $color-white;
  text-align: left;
  vertical-align: middle;

  .popup_title {
    position: relative;
    display: block;
    width: 100%;
    font-size: $font-large-1;
    color: $color-default;

    .popup_close {
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background: url(../../assets/img/btn_close.svg) no-repeat;
      font-size: 0;
      color: transparent;
      cursor: pointer;
    }
  }
  .note {
    margin-top: 10px;
    font-size: $font-small;
    color: #767676;
  }
  .popup_body {
    margin-top: 32px;
  }

  &.wide {
    min-width: 1280px;
    // width: 100%;
    // max-width: 1150px;
  }
}

.buttonContianer {
  display: flex;
  justify-content: center;
  gap: 10px;
}
