@import '@assets/scss/style.module.scss';

.authTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%; /* 화면 높이에 맞게 설정 */
  .authHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 36px;
    height: 32px;
    .box_left {
      display: flex;
      align-items: center;
      .total_count {
        span {
          color: #f65f8d;
        }
      }
      .selectbox {
        width: 72px;
        margin-left: 16px;
      }
    }
    .box_right {
      .btn {
        width: 80px;
      }
    }
  }

  /* Table */
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    text-align: center;
    border-top: 3px solid black;
    /* Table Header */
    .theaderRow {
      padding: 12px 0 16px;
      border-bottom: 1px solid #ccc;
      &:first-child {
        border-top: 2px solid #222;
      }
    }
    .theader {
      padding: 15px;
      font-weight: bold;
    }
    /* Table Body */

    .tbodyRow {
      border-bottom: 1px solid #ccc;
      .tbodyList {
        padding: 10px 0;
        border-right: 1px solid #ccc;
      }
      .tbodyLast {
        padding: 10px 0;
      }
      .tbodyTitle {
        padding: 10px 0;
        border-right: 1px solid #ccc;
        font-weight: bold;
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .pageIndex {
      margin-left: 15px;
    }
  }

  .pagination .nav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #fff;
    font-size: 0;
    color: transparent;
  }
  .pagination .nav:after {
    content: '';
    display: block;
    width: 14px;
    height: 12px;
  }
  .pagination .nav.first {
    margin-right: 6px;
  }
  .pagination .nav.first:after {
    transform: rotate(180deg);
    background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
  }
  .pagination .nav.prev:after {
    transform: rotate(180deg);
    background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
  }
  .pagination .nav.next:after {
    background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
  }
  .pagination .nav.last {
    margin-left: 6px;
  }
  .pagination .nav.last:after {
    background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
  }
  .pagination .nav:disabled {
    background: #f4f4f4;
  }
  .pagination .nav:disabled:after {
    opacity: 0.2;
  }
  .pagination .order {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0 12px;
  }
  .pagination .order li {
    margin: 0 12px;
  }
  .pagination .order li button {
    font-size: 14px;
  }
  .pagination .order li.on button {
    font-weight: 700;
  }

  .pageIndexInfo {
    margin-left: 15px;
  }

  .btn {
    &.btn03 {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 12px;
      border-radius: 16px;
      &.type02 {
        background: #00aab7;
        color: #fff;
      }
    }
  }
}

.selectbox {
  background: #fff;
}

.selectbox.type01 {
  position: relative;
  min-width: 90%;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
}

.selectbox.type01 .label {
  position: relative;
  display: flex;
  align-items: center;
  width: inherit;
  height: inherit;
  border: 1px solid #ddd;
  padding-left: 15px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}
.selectbox.type01 .label:after {
  position: absolute;
  right: 16px;
  top: 13px;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: url('../../assets/img/arr_selectbox.svg') 50% 50% no-repeat;
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.inputWrap .input_text {
  width: 100%;
}

.input_text input[type='text']:focus {
  border-color: #222;
}

.input_text input[type='text'] {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
  color: #222;
}

.btn {
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;

    &.type04 {
      min-width: 60px;
      height: 32px;
      padding: 0 10px;
      background: #363636;
      color: white;
    }

    &:disabled {
      background: $color-bg-2;
      color: $color-white;
      cursor: default;
    }
  }
  &.btn02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    &.type04 {
      padding: 0 12px;
      border: 1px solid #555;
      color: $color-default;
    }
  }
}

.btn_txt04 {
  display: inline-block;
  text-decoration: underline;
}

.excelPosition {
  margin-left: 1500px;
}

.headerInput {
  -webkit-appearance: auto;
}

.checkboxDivider {
  margin-bottom: 10px;
  padding: 10px;
}

.input_check input[type='checkbox'] {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  font-size: 0;
}

.input_check label {
  display: inline-block;
  min-height: 24px;
  padding-left: 28px;
  background: url('../../assets/img/input_check.svg') no-repeat 0 0;
  cursor: pointer;
}

.input_check.reverse input[type='checkbox']:checked + label {
  background-position: 100% 0;
  font-weight: normal;
}

.input_check input[type='checkbox']:checked + label {
  background: url('../../assets/img/input_check_checked.svg') no-repeat 0 0;
  font-weight: 700;
}

.input_check.reverse input[type='checkbox']:checked + label {
  background-position: 100% 0;
  font-weight: normal;
}

.input_check.reverse label {
  padding-left: 0;
  padding-right: 32px;
  background-position: 100% 0;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}
