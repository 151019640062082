@import '@assets/scss/style.module.scss';

.textArea {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $color-border;
  background: $color-white;
  font-size: $font-medium;
  color: $color-default;

  &:focus {
    border-color: $color-default;
  }
  &::placeholder {
    color: #aaa;
  }

  &.required {
    &:after {
      content: '*';
      font-size: inherit;
      color: #be3455;
    }
  }

  &.disabled {
    background: $color-bg-4;
    color: #aaa;
  }

  &:read-only {
    background: $color-bg-4;
    color: #aaa;
  }
  &:read-only:focus {
    border-color: #ddd;
  }
}
