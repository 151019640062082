@import '@assets/scss/style.module.scss';
.container {
  display: flex;
  gap: 50px;
  align-items: center;
  z-index: 3;

  .timeSetter {
    position: relative;
    .timeInput {
      width: 150px;
      height: 48px;
      padding: 0 16px;
      border-radius: 8px;
      border: 1px solid #ddd;
      background: #fff;
      font-size: 16px;
      color: #222;
    }
    .buttonWrap {
      .timeNav {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 0;
        width: 30px;
        height: 24px;
        border: 1px solid #ddd;
        background: #fff;
        color: transparent;
      }

      .timeNav.up {
        border-radius: 0 8px 0 0;

        &.disabled {
          pointer-events: none;
        }
      }
      .timeNav.up:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('../../assets/img/btn_updown.svg') no-repeat 50% 50%;
      }

      .timeNav.down {
        top: auto;
        bottom: 0;
        border-radius: 0 0 8px 0;
        border-top: 1px solid #ddd;
        &.disabled {
          pointer-events: none;
        }
      }
      .timeNav.down:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('../../assets/img/btn_updown.svg') no-repeat 50% 50%;
        transform: rotate(180deg);
      }
    }
  }

  .datePicker {
    width: 120px;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
    color: #222;
  }

  .timePicker {
    width: 120px;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
    color: #222;
  }

  .react-datepicker__day--highlighted-custom-1 {
    background-color: red;
  }
  // Calendar2 css
  .calendarIcon {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }

  .calIcon {
    cursor: pointer;
    left: 115px;
  }
  &.time {
    .datepick + .input_text {
      margin-left: 10px;
    }
    .timepick {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-left: 10px;
      background: url('../../assets/img/ico_time.svg') no-repeat;
    }
  }
  .timepicker {
    margin-left: 20px;
  }
}
