@import '@assets/scss/style.module.scss';

.select_user {
  position: absolute;
  right: 100px;
  top: 17px;
  display: flex;
  column-gap: 20px;
  align-items: center;
  @include pc-size-ml {
    right: 60px;
  }

  .messageBox {
    position: relative;
    width: 24px;
    height: 18px;
    background: center/cover url('../../../assets/img/btn_message.svg')
      no-repeat;
    font-size: 0;
    color: transparent;
    .num {
      position: absolute;
      right: -8px;
      top: -10px;
      padding: 0 2px;
      border-radius: 7px;
      background: #00aab7;
      font-size: 12px;
      color: #fff;
    }
  }

  .adjustment_notice {
    display: inline-flex;
    align-items: center;
    height: 28px;
    padding: 0 16px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #666;
    background: $color-white;
  }
}
