@import '@assets/scss/style.module.scss';

.stepGuideText {
  position: relative;
  margin-top: 20px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
}

.applyStatus {
  display: flex;
  margin-top: 10px;
  width: 100%;

  li {
    position: relative;
    width: 50%;
    padding: 12px 0;
    border-top: 2px solid #111;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
}

.foc_title {
  margin-left: 10px;
}

.box_left {
  display: flex;
  align-items: center;
}

.box_right {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.btn {
  vertical-align: middle;
  margin-right: 5px;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
    border-radius: 8px;
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    padding: 0px 12px;
    border-radius: 16px;
  }
  &.type01 {
    color: rgb(255, 255, 255);
    background: rgb(54, 54, 54);
  }
}
