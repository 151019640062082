@import '@assets/scss/style.module.scss';

.alert {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 440px;
  padding: 40px;
  border-radius: 20px;
  background: $color-white;
  text-align: center;
  vertical-align: middle;

  .alert_body {
    margin-bottom: 36px;
    white-space: pre-wrap;
  }
}

.buttonContianer {
  display: flex;
  justify-content: center;
  gap: 10px;
}
