@import '@assets/scss/style.module.scss';

.stepGuideText {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
  .btn_container6 {
    position: absolute;
    right: 0;
    top: -6px;
  }
}
.addCouponList {
  overflow-y: auto;
  height: 240px;
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  .delete {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    background: url('../../../../assets/img/btn_delete.svg') no-repeat;
    vertical-align: middle;
    font-size: 0;
    color: transparent;
  }
}

.totalCount {
  margin-top: 20px;
  text-align: right;
}

.ico_prev {
  display: inline-block;
  width: 8px;
  height: 14px;
  margin-right: 10px;
  vertical-align: middle;
  background: url('../../../../assets/img/arr_right4.svg') no-repeat;
  transform: rotate(180deg);
}

.stepsNav {
  border-top: 2px solid #222;
  margin-top: 30px;
  padding-top: 16px;
  text-align: right;
}

.marginL10 {
  margin-left: 10px;
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type01 {
      background: $color-default;
      color: $color-white;
    }
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;
    &.type01 {
      background: #363636;
      color: $color-white;
    }
    &.type03 {
      border: 1px solid #333;
      background: $color-white;
      color: $color-default;
    }
  }
}
.btn_large {
  width: 100%;
  margin-top: 36px;
}

.alert_body {
  text-align: center;
}
