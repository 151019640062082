@import '@assets/scss/style.module.scss';

/* 권한관리 */

.cont_area {
  width: 100%;
  .cont {
    width: 100%;
    display: none;
  }
}
.searchOptionArea {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  // margin-bottom: 20px;
  width: 100%;
  background: #efefef;
  .innerBox {
    display: flex;
    align-items: center;
    & + .innerBox {
      margin-left: 65px;
    }
  }
  .title {
    margin-right: 16px;
    font-weight: $font-weight-regular;
    white-space: noWrap;
  }
  .status {
    flex: 1;
  }
  .option_detail {
    flex: auto;
    .inputWrap {
      width: 100%;
    }
    .btn {
      width: 80px;
    }
  }
}

.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
  .box_left {
    display: flex;
    align-items: center;
    .total_count {
      span {
        color: $color-pink;
      }
    }
    .selectbox {
      width: 72px;
      margin-left: 16px;
    }
  }
  .box_right {
    .btn {
      width: 80px;
    }
  }
}
.dataTable01 {
  margin-top: 22px;
  thead {
    tr:first-child {
      th {
        border-top: 2px solid $color-default;
      }
    }
    th {
      border-top: 1px solid #ccc;
      padding: 12px 0 16px;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    & + tbody {
      tr:first-child {
        td {
          border-top: 1px solid #ccc;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    tr {
      &:first-child {
        td {
          border-top: 2px solid $color-default;
        }
      }
    }
  }
  &.type02 {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    table {
      min-width: 120%;
      max-width: 10000px;
    }
  }
}
.table_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.in_popup_input_table {
  width: 554px;
}

.selectbox {
  background: #fff;
}

.selectbox.type01 {
  position: relative;
  min-width: 90%;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
}

.selectbox.type01 .label {
  position: relative;
  display: flex;
  align-items: center;
  width: inherit;
  height: inherit;
  border: 1px solid #ddd;
  padding-left: 15px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}
.selectbox.type01 .label:after {
  position: absolute;
  right: 16px;
  top: 13px;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: url('../../assets/img/arr_selectbox.svg') 50% 50% no-repeat;
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.inputWrap .input_text {
  width: 100%;
}

.input_text input[type='text']:focus {
  border-color: #222;
}

.input_text input[type='text'] {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
  color: #222;
}

.btn {
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;

    &.type04 {
      min-width: 60px;
      height: 32px;
      padding: 0 10px;
      background: #363636;
      color: white;
    }

    &:disabled {
      background: $color-bg-2;
      color: $color-white;
      cursor: default;
    }
  }
  &.btn02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    &.type04 {
      padding: 0 12px;
      border: 1px solid #555;
      color: $color-default;
    }
  }
}

.btn_txt04 {
  display: inline-block;
  text-decoration: underline;
}

.excelPosition {
  margin-left: 1500px;
}

.headerInput {
  -webkit-appearance: auto;
}

.checkboxDivider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
}

.input_check input[type='checkbox'] {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  font-size: 0;
}

.input_check label {
  display: inline-block;
  min-height: 24px;
  padding-left: 28px;
  background: url('../../assets/img/input_check.svg') no-repeat 0 0;
  cursor: pointer;
}

.input_check.reverse input[type='checkbox']:checked + label {
  background-position: 100% 0;
  font-weight: normal;
}

.input_check input[type='checkbox']:checked + label {
  background: url('../../assets/img/input_check_checked.svg') no-repeat 0 0;
  font-weight: 700;
}

.input_check.reverse input[type='checkbox']:checked + label {
  background-position: 100% 0;
  font-weight: normal;
}

.input_check.reverse label {
  padding-left: 0;
  padding-right: 32px;
  background-position: 100% 0;
}
