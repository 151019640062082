@import '@assets/scss/style.module.scss';

.gnb {
  margin-left: 80px;
  @include pc-size-ml {
    margin-left: 40px;
  }
  .space {
    display: flex;
    .depth1 {
      margin-right: 40px;
      font-size: $font-large;
      font-weight: $font-weight-medium;
      cursor: pointer;
      @include pc-size-ml {
        margin-right: 30px;
      }
      &:last-child {
        margin-right: 0;
      }
      .topMenuTitle {
        &:hover {
          color: $color-primary;
        }
      }
      .depth2_wrap {
        display: flex;
        position: absolute;
        min-height: 45px;
        left: 0;
        top: 65px;
        width: 100%;
        padding: 0px 0 10px 343px;
        background: #363636;
        @include pc-size-ml {
          padding-left: 263px;
        }
        .depth2 {
          margin-left: 40px;
          margin-top: 10px;
          @include pc-size-ml {
            margin-left: 30px;
          }
          font-size: $font-medium;
          color: $color-white;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
        }
        .default {
          visibility: hidden;
        }
      }
    }
  }
}
