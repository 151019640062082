@import '@assets/scss/style.module.scss';

.btnContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  .btn_wrapper {
    width: 80px;
  }
}
