@import '@assets/scss/style.module.scss';

.textButton {
  cursor: pointer;
  &.btn_txt01 {
    display: inline-block;
    padding-right: 29px;
    background: url('../../assets/img/arr_right.svg') no-repeat 100% 50%;
    font-size: $font-medium;
  }
  &.btn_txt02 {
    display: inline-block;
    padding-right: 29px;
    background: url('../../assets/img/ico_add.svg') no-repeat 100% 50%;
    font-size: $font-medium;
  }
  &.btn_txt03 {
    display: inline-block;
    padding-right: 26px;
    background: url('../../assets/img/ico_plus.svg') no-repeat 100% 50%;
    font-size: $font-medium;
  }
  &.btn_txt04 {
    display: inline-block;
    text-decoration: underline;
  }
}
