/* 파일 필드 숨기기 */
.fileBox input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.btn {
  vertical-align: middle;

  &.type01 {
    border: 1px solid #555;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    background: #fff;
    font-size: 14px;
    color: #222;
    margin-left: 5px;
  }
}
