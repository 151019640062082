@import '@assets/scss/style.module.scss';

.searchOptionArea {
  margin-top: 20px;
  .flex {
    width: 50%;

    .sizeS {
      width: 160px;
    }
  }
}

.tableButtonContainer {
  display: flex;
  column-gap: 10px;
}

.hrBoldBlack {
  border: 0;
  height: 2px;
  background-color: black;
}

.detailContainer {
  .detail {
    display: flex;
    margin: 20px 10px;
    .detailTitle {
      width: 80px;
      margin-right: 10px;
    }
  }
}

.detailContent {
  margin: 20px 10px;
  height: 230px;
  overflow: auto;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .buttonWidth {
    width: 170px;
  }
}

.cursor {
  cursor: pointer;
}

.resetIcon {
  width: 30px;
  height: 31px;
  margin: 0 10px;
  padding: 5px 6px 6.7px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #e3e3e3;
  cursor: pointer;
}

.leftControls {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .fontBlue {
    color: #00aab7;
  }
}
