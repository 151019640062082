.container {
  margin-bottom: 35px;
  .rowbox {
    display: flex;

    .viewer {
      width: calc(50% - 40px);
      height: 400px;
      padding: 20px;
      margin-top: 20px;
      border: 2px solid gray;
    }
  }
}
