@import '@assets/scss/style.module.scss';

.detailBoxLarge {
  display: flex;
  flex: 1;
  gap: 20px;
  padding: 24px 20px;
  background: #ececec;

  .title_01 {
    width: 16%;
    font-size: 20px;
  }
  .status {
    position: relative;
    flex: 1;
    padding: 20px;
    background: #f5f5f5;
    .new {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
      height: 40px;
      padding: 0 16px;
      line-height: 40px;
      border-radius: 20px;
      background: #ececec;
      @include pc-size-l {
        display: inline-block;
        position: static;
        height: 30px;
        line-height: 30px;
        margin-top: 10px;
      }

      .num {
        cursor: pointer;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      min-height: 68px;
      margin-top: 20px;
      @include pc-size-l {
        display: block;
      }
      .case {
        color: $color-primary;
        .num {
          font-size: 56px;
          line-height: 0.8em;
          // cursor: pointer;
        }
      }
      .value {
        margin-left: 32px;
        @include pc-size-l {
          margin-left: 0;
          margin-top: 16px;
        }

        .num {
          cursor: pointer;
        }

        .ico_act {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 6px;
          border-radius: 50%;
          &.type01 {
            background: $color-act-1;
          }
          &.type02 {
            background: $color-act-2;
          }
          &.type03 {
            background: $color-act-3;
          }
          &.type04 {
            background: $color-act-4;
          }
        }
      }
    }
  }
}
//승훈추가
.disabledDetailBox {
  opacity: 0.3 !important;
}
