@import '@assets/scss/style.module.scss';

.register_link {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  &.single {
    display: block;
    text-align: right;
  }
}

.checkPopup {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  padding: 20px;
}

.isIdValid {
  color: gray;
  font-size: smaller;
  margin-left: 5px;
}
