@import '@assets/scss/style.module.scss';
.title {
  margin-bottom: 16px;
  font-size: $font-large-1;
  color: $color-default;
}

.desc {
  margin-top: 12px;
}
