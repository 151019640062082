@import '@assets/scss/style.module.scss';

.wrap {
  position: relative;
  background-color: #fbfbfb;
  min-width: 1280px;
  // min-height: 100vh;
  // position: relative;

  //dashboard layout Header
  .header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    min-width: 1280px;
    width: 100%;
    height: 65px;
    padding: 32px 100px 24px;
    background: inherit;
    @include pc-size-ml {
      padding: 0px 60px 0px;
    }
  }

  //outlet style
  .main {
    flex: 1;
    margin-top: 125px;
  }

  .contents {
    min-height: calc(100vh - 156px - 56px);
    padding: 24px 100px 48px;
    @include pc-size-ml {
      padding: 0px 60px 48px;
    }
  }

  //footer style
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px 48px;
    text-align: right;
  }

  //TODO: 아직 쓰지 않는 스타일
  .partner {
    position: absolute;
    right: 100px;
    top: 112px;
    @include pc-size-ml {
      right: 60px;
    }
  }

  .lnb {
    width: 360px;
    margin-top: 96px;
    padding: 0 48px;
    .depth1 {
      .depth2_wrap {
        display: none;
      }
    }
  }

  .contents {
    min-height: calc(100vh - 156px - 56px);
    padding: 0px 100px 48px;
    @include pc-size-ml {
      padding: 0px 60px 48px;
    }
  }

  .breadcrumbs {
    ul {
      display: flex;
      li {
        font-size: $font-small;
        &:after {
          content: '>';
          margin: 0 6px;
        }
        &:last-child {
          a {
            color: $color-green;
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
