.input_table {
  // width: 100%;
  // table-layout: fixed;

  tbody {
    tr {
      border: 0;
    }
    th {
      border: 0;
      padding-top: 18px;
      vertical-align: top;
      text-align: left !important;
      &.required {
        &:after {
          content: '*';
          font-size: inherit;
          color: #be3455;
        }
      }
      span {
        &.required {
          &:after {
            content: '*';
            font-size: inherit;
            color: #be3455;
          }
        }
      }
    }
    td {
      border: 0;
      padding: 5px 0;
      height: 58px;

      .inputWrap {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .inputText {
          width: 100%;
        }

        &.isAuthNumber {
          width: 437px;
        }

        &.isPhoneType {
          width: 437px;
        }
        &.type_phone {
          .inputText {
            margin-left: 21px;
            &:first-child {
              margin-left: 0;
              &:after {
                display: none;
              }
            }
            &:after {
              position: absolute;
              left: -18px;
              top: 15px;
              content: '-';
            }
          }
        }
      }

      .inputWrap + .inputWrap {
        margin-top: 10px;
      }

      .inputWrap + .inputWrap {
        margin-top: 10px;
      }
      & div.hasSlash {
        display: flex;
        .inputText {
          // width: 231px;

          & + .inputText {
            position: relative;
            margin-left: 21px;
            &:before {
              position: absolute;
              left: -12px;
              top: 12px;
              content: '/';
              color: #aaa;
            }
          }
        }
        .btn {
          margin-left: 8px;
        }
      }
    }
  }
}

.hasTitleMinWidth {
  min-width: 125px;
}
