.container {
  padding: 20px;
  h2,
  h3 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }
}
