@import '@assets/scss/style.module.scss';

.flex {
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.innerBox {
  display: flex;
  align-items: center;
  & + .innerBox {
    margin-left: 65px;
  }
}
.title {
  margin-right: 16px;
  font-weight: $font-weight-regular;
  white-space: noWrap;
}
.status {
  flex: 1;
}
.option_detail {
  flex: auto;
  .inputWrap {
    width: 100%;
  }
  .btn {
    width: 80px;
  }
}
.rowBox {
  display: flex;
  float: left;
}

.btn {
  min-width: 76px !important;
}

.selectbox {
  background: $color-white;
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
  }
  &.sizeXS {
    width: 96px;
  }
}

.form {
  display: inline-flex;
  width: 100%;
  gap: 8px;
  margin-left: 5px;
}
.button {
  margin-left: 10px;
}

.buttonContainer {
  width: 72px;
}
