@import '@assets/scss/style.module.scss';

.searchOptionArea {
  .innerBox + .innerBox {
    margin-left: 65px;
  }

  .option_detail {
    .btn {
      min-width: 80px !important;
    }
  }

  &.row {
    .flex {
      .inputWrap {
        width: 100%;
        flex: 1;
        margin-left: 10px;

        .input_text {
          width: 100%;
        }
      }
    }
  }
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}

.input_radio {
  margin-right: 20px;
  input[type='radio'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
  }
  label {
    display: inline-block;
    min-height: 24px;
    padding-left: 32px;
    background: url('../../../../assets/img/input_radio.svg') no-repeat 0 0;
    cursor: pointer;
    span {
      display: inline-block;
      margin-top: 4px;
      line-height: 16px;
    }
  }
  input[type='radio']:checked + label {
    background: url('../../../../assets/img/input_radio_checked.svg') no-repeat
      0 0;
    font-weight: $font-weight-bold;
  }
  input[type='radio']:disabled + label {
    background: url('../../../../assets/img/input_radio_disabled.svg') no-repeat
      0 0;
    color: #aaa;
  }
  &.single {
    label {
      padding-left: 24px;
    }
  }
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
