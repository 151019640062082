.label {
  text-align: right;
  margin-right: 30px;

  &.auto {
    width: auto;
  }

  &.large {
    width: 100px;
  }

  width: 100px;

  .required {
    color: red;
    margin-right: 5px;
  }
}
