@import '@assets/scss/style.module.scss';

.dash {
  margin-left: 46px;
  margin-right: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml40 {
  margin-left: 40px;
}
