@import '@assets/scss/style.module.scss';

.input {
  &[type='text'],
  &[type='password'],
  &[type='email'],
  &[type='number'] {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background: $color-white;
    font-size: $font-medium;
    color: $color-default;

    &:focus {
      border-color: $color-default;
    }
    &::placeholder {
      color: #aaa;
    }
    &.disabled,
    &[readOnly] {
      background: $color-bg-4;
      color: #aaa;
    }

    &.bytePadding {
      padding-right: 120px;
    }

    &.type02 {
      width: 130px;
      height: 32px;
      padding: 0 12px;
    }
  }
}
