@import '@assets/scss/style.module.scss';

.searchContainer {
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

.inputWrap {
  width: 100%;
}

.input_table_wrap {
  padding-top: 20px;
}

.form {
  display: flex;
  gap: 10px;
}

.input {
  width: 100%;
}

.title {
  margin-right: 16px;
  font-weight: 400;
  white-space: noWrap;
}

.btn {
  min-width: 76px !important;
}
