@import '@assets/scss/style.module.scss';
.container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .dimmed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }

  .layerPopup {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 60px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      vertical-align: middle;
    }
  }
}
// FIXME: Modal Portal test
// .custom-modal-portal {
//   position: relative;
//   z-index: 1;
//   display: inline-block;
//   width: 440px;
//   padding: 40px;
//   border-radius: 20px;
//   background: $color-white;
//   text-align: center;
//   vertical-align: middle;
// }
// .custom-modal-overlay {
//   background-color: 'rgba(0, 0, 0, 0.75)';
//   position: 'fixed';
//   left: 0;
//   top: 0;
//   width: '100%';
//   height: '100%';
//   background: 'rgba(0, 0, 0, 0.75)';
//   display: 'flex';
//   align-items: 'center';
//   justify-content: 'center';
//   max-height: '100%';
//   padding: '60px 0';
//   overflow-x: 'hidden';
//   overflow-y: 'auto';
//   z-index: 100;
// }

.alert {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 440px;
  padding: 40px;
  border-radius: 20px;
  background: $color-white;
  text-align: center;
  vertical-align: middle;
  .alert_title {
    margin-bottom: 16px;
    font-size: $font-large-1;
    color: $color-default;
  }
  .alert_body {
    // TODO: Props로 처리해야 함
    margin-bottom: 36px;
  }
}

.buttonContianer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.popup_close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  background: url(../../assets/img/btn_close.svg) no-repeat;
  font-size: 0;
  color: transparent;
  cursor: pointer;
}
