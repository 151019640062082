@import '@assets/scss/style.module.scss';

.searchOptionArea {
  .innerBox {
    width: 100%;
  }

  // .innerBox + .innerBox {
  //   margin-left: 101px;
  // }

  .option_detail {
    .btn {
      min-width: 80px !important;
    }
  }

  &.row {
    .inputWrap {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 1;
      margin-left: 10px;

      .input_text {
        width: 100%;
      }
    }
  }
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.input_text {
  position: relative;
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}

.dateBox {
  width: 100%;
  display: flex;
  align-items: center;
  .radioBox {
    display: flex;
    align-items: center;
    margin-left: 40px;
    gap: 20px;
  }
}
