@import '@assets/scss/style.module.scss';

.container {
  padding: 20px 100px;

  h2 {
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
}
