@import '@assets/scss/style.module.scss';

.btn.btn01.type04 {
  min-width: 60px;
  height: 32px;
  padding: 0 10px;
  background: #363636;
  color: #fff;
}

.pointer {
  cursor: pointer;
  text-decoration: underline;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
}

.note {
  margin-top: 10px;
  font-size: 14px;
  color: #767676;
}

.popup_body {
  margin-top: 32px;
}

.in_popup_input_table {
  width: 554px;
}

.btn_container4 {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin-top: 36px;
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 25px;
}

.input_text::after {
  position: absolute;
  left: -18px;
  top: 15px;
  content: '-';
}

.input_text {
  width: 100%;
}

.input_table tbody th {
  padding-top: 18px;
  vertical-align: top;
  text-align: left;
}

.col_width {
  width: 154px;
}
