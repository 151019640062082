@import '@assets/scss/style.module.scss';

// .cont_area {
//   width: 100%;
//   .cont {
//     width: 100%;
//     display: none;
.areaTitle {
  margin-top: 32px;
  font-size: 18px;
}
.shippingManage {
  .row {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    background: #ececec;
    &.first {
      background: #dedede;
    }
    .titleArea {
      width: 280px;
      dt {
        font-weight: bold;
        font-size: 18px;
      }
      .type_color_red {
        display: block;
        margin-top: 4px;
        font-size: 14px;
        font-weight: normal;
        color: #c30036;
      }
      dd {
        margin-top: 54px;
        font-weight: bold;
        color: #00aab7;
        .num {
          line-height: 1;
          font-size: 48px;
        }
      }
    }

    .link {
      display: block;
      width: 100%;
      height: 100%;
      padding: 24px 20px;
      background: #f5f5f5;
      dl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .value {
          font-weight: bold;
          color: #00aab7;
          line-height: 1;
          .num {
            font-size: 32px;
            cursor: pointer;
          }
          .disabled_num {
            font-size: 32px;
          }
        }
        .notConnected {
          position: absolute;
          right: 20px;
          bottom: 24px;
          border-radius: 20px;
          padding: 4px 12px;
          background: #ececec;
        }
        .d_day {
          position: absolute;
          left: 20px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          width: calc(100% - 40px);
          padding: 4px 20px;
          border-radius: 20px;
          background: #ececec;
        }
      }
    }
  }
}

.alarmTalkCnt {
  display: flex;
  justify-content: space-between;
}

dl {
  white-space: pre-line;
}
