@import '@assets/scss/style.module.scss';

.selectbox {
  background-color: #fff;
  min-width: 96px;
  &.fullWidth {
    width: 100% !important;
  }

  &.type01 {
    position: relative;
    width: 418px;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;
    .label {
      position: relative;
      display: flex;
      align-items: center;
      width: inherit;
      height: inherit;
      border: 1px solid $color-border;
      padding-left: 15px;
      border-radius: 8px;
      font-size: $font-medium;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 16px;
        top: 13px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('../../assets/img/arr_selectbox.svg') 50% 50% no-repeat;
      }

      .icon {
        position: absolute;
        right: 16px;
        top: 13px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;

        .up {
          top: 17px;
          transform: rotate(180deg);
        }
      }
    }
    .optionList {
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 1;
      display: none;
      width: 100%;
      border-radius: 0 0 8px 8px;
      background: $color-white;
      color: $color-gray-3;
      overflow: hidden;

      &.showOption {
        display: block;
      }

      .optionItem {
        padding: 9px 16px;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        &:hover {
          background: #eee;
        }
        &:first-child {
          border-top: 1px solid $color-border;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
          border-bottom: 1px solid $color-border;
        }
      }
    }
    &.active {
      z-index: 2;
      .label {
        border-color: $color-default;
        border-radius: 8px 8px 0 0;
        &:after {
          top: 17px;
          transform: rotate(180deg);
        }
      }
      .optionList {
        display: block;
        .optionItem {
          border-color: $color-default;
          &:hover {
            color: $color-default;
          }
        }
      }
    }
  }
  &.type02 {
    position: relative;
    width: 170px;
    height: 36px;
    border-radius: 8px;
    cursor: pointer;
    .ico {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 36px;
      height: 36px;
      &.ico_user {
        background: url('../../assets/img/ico_user.svg') no-repeat 0 0 / 36px;
      }
    }
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 36px;
      padding: 0 44px 0 56px;
      border-radius: 18px;
      background: #efefef;
      text-align: left;
      font-size: $font-small;
      &:after {
        position: absolute;
        right: 12px;
        top: 6px;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url('../../assets/img/arr_selectbox2.svg') no-repeat;
      }
    }
    .optionList {
      position: absolute;
      left: 0;
      top: 36px;
      z-index: 1;
      display: none;
      width: 100%;
      padding: 0 12px 0 16px;
      border-radius: 0 0 8px 8px;
      background: #efefef;
      overflow: hidden;
      .optionItem {
        padding: 10px 0;
        line-height: 1;
        font-size: $font-small;
      }
    }
    &.active {
      .label {
        border-radius: 18px 18px 0 0;
      }
      .optionList {
        display: block;
      }
    }
  }
  &.type03 {
    position: relative;
    width: 150px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    .label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 32px;
      padding: 0 34px 0 12px;
      border-radius: 16px;
      background: #efefef;
      text-align: left;
      font-size: $font-small;
      &:after {
        position: absolute;
        right: 12px;
        top: 13px;
        content: '';
        display: block;
        width: 10px;
        height: 6px;
        background: url('../../assets/img/arr_selectbox3.svg') no-repeat;
      }
    }
    .optionList {
      position: absolute;
      left: 0;
      top: 32px;
      z-index: 1;
      display: none;
      width: 100%;
      padding: 0 12px;
      border-radius: 0 0 16px 16px;
      background: #efefef;
      overflow: hidden;
      .optionItem {
        padding: 8px 0;
        line-height: 1;
        font-size: $font-small;
      }
    }
    &.active {
      .label {
        border-radius: 16px 16px 0 0;
      }
      .optionList {
        display: block;
      }
    }
  }
  &.type04 {
    position: relative;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    .label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 32px;
      padding: 0 34px 0 12px;
      border-radius: 8px;
      border: 1px solid #555;
      background: $color-white;
      text-align: left;
      font-size: $font-small;
      @include textEllipsis;
      &:after {
        position: absolute;
        right: 12px;
        top: 4px;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url('../../assets/img/arr_selectbox2.svg') no-repeat;
      }
    }
    .optionList {
      position: absolute;
      left: 0;
      top: 32px;
      z-index: 1;
      display: none;
      width: 100%;
      border-radius: 0 0 8px 8px;
      background: $color-white;
      overflow: hidden;
      .optionItem {
        padding: 8px 12px;
        border-left: 1px solid #555;
        border-right: 1px solid #555;
        line-height: 1;
        font-size: $font-small;
        &:last-child {
          border-radius: 0 0 8px 8px;
          border-bottom: 1px solid #555;
        }
      }
    }
    &.active {
      .label {
        border-radius: 8px 8px 0 0;
      }
      .optionList {
        display: block;
      }
    }
  }
}
.disabled {
  background: #eee !important;
  color: #aaa !important;

  .label {
    &:after {
      display: none !important;
    }
  }
}

.title {
  flex: 1;
  margin-right: 16px;
  font-weight: 400;
  white-space: noWrap;
}
