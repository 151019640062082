@import '@assets/scss/style.module.scss';

.register_input2 {
  margin-top: 36px;

  .flexEnd {
    display: flex;
    justify-content: flex-end;
  }
}

.note {
  margin-top: 10px;
  font-size: $font-small;
  color: #767676;
}
