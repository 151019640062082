@import '@assets/scss/style.module.scss';

.quickMenu {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99;
  text-align: center;
  transform: translateY(-50%);
  padding-right:16px;
  // ul
  .quickMenuList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 36px 16px;
    border-radius: 20px 0 0 20px;
    background: #363636;
  }

  .disabledList {
    display: flex;
    flex-direction: column;
    min-width: 80px;
    gap: 20px;
    padding: 16px 16px;
    border-radius: 20px 0 0 20px;
    background: #363636;
  }

  //li
  .activeQmenu {
    display: block;
    margin-top: 32px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      display: block !important;
      margin-top: 0;
    }
  }
  .disabledQmenu {
    display: none;
    margin-top: 32px;
  }
  .q_menu {
    display: block;
    min-width: 24px;
    padding-top: 22px;
    margin: 0 auto;
    font-size: $font-small;
    color: $color-white;
    background-repeat: no-repeat;
    background-position: 50% 0;
    cursor: pointer;
  }
  .active {
  }
  .qMenuOnOff {
    height: 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }

  .goLGEWrap {
    margin-top: 20px;
    .goLGE {
      display: block;
      width: 58px;
      height: 58px;
      padding-top: 29px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #eee;
      background-repeat: no-repeat;
      background-position: 50% 25%;
      background-size: 16px;
      font-size: $font-small;
      color: #be3455;
      cursor: pointer;
    }
  }
}
