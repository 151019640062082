@import '@assets/scss/style.module.scss';

.register_input {
  margin-top: 60px;
}
.register_input2 {
  margin-top: 36px;
}
.register_input3 {
  margin-top: 16px;
}

.fileInput {
  display: none;
}

.note {
  margin-top: 10px;
  font-size: $font-small;
}
