@import '@assets/scss/style.module.scss';

// Title
.pageTitle {
  margin-top: 24px;
  font-size: $font-large-2;
}
.sectionTitle02 {
  margin-top: 20px;
  font-size: $font-large-1;
}

// 체크박스, 라디오 가로 정렬
.inputCheckWrap {
  display: flex;
  align-items: center;
  gap: 48px;
  .btn {
    min-width: auto !important;
    width: auto;
  }
  &.type02 {
    gap: 20px;
  }
}

.note {
  font-size: 14px;
  color: #767676;
  margin-top: 10px;
}

.btn {
  vertical-align: middle;

  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
  }
  &.type02 {
    background: #00aab7;
    color: #fff;
  }
  &.type03 {
    background: #555;
    color: $color-white;
  }
}

span.note {
  display: inline-block;
  margin: 0 0 0 20px;
  vertical-align: middle;
}

.input_text {
  position: relative;
  input[type='text'],
  input[type='password'] {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background: $color-white;
    font-size: $font-medium;
    color: $color-default;
    &:focus {
      border-color: $color-default;
    }
    &::placeholder {
      color: #aaa;
    }
    &:read-only {
      background: $color-bg-4;
      color: #aaa;
    }
    &:read-only:focus {
      border-color: #ddd;
    }
  }
  &.type02 {
    input[type='text'] {
      height: 32px;
      padding: 0 12px;
    }
  }
  &.has-byte {
    input[type='text'] {
      padding-right: 100px;
    }
  }
  .byte {
    position: absolute;
    right: 16px;
    top: 15px;
    z-index: 1;
    font-size: $font-small;
    color: #aaa;
  }
  &.sizeXS {
    width: 130px;
  }
  &.sizeS {
    width: 243px;
  }
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin: 5px;
  .input_text,
  .input_file {
    width: 100%;
  }
  .btn {
    min-width: 76px !important;
    flex: 1 0 auto;
  }
  &.type02 {
    width: 437px;
  }
  &.type03 {
    width: 578px;
    .selectbox {
      width: 100%;
    }
  }
  &.type04 {
    align-items: center;
    .input_text {
      width: 50%;
    }
    .input_check {
      margin: 0 20px;
    }
    .note {
      margin-top: 0;
    }
  }
  &.type05 {
    .input_text.sizeXS {
      width: 160px;
    }
  }
  &.type_phone {
    .input_text {
      margin-left: 21px;
      &:first-child {
        margin-left: 0;
        &:after {
          display: none;
        }
      }
      &:after {
        position: absolute;
        left: -18px;
        top: 15px;
        content: '-';
      }
    }
  }
  .selectbox {
    flex: 1 0 auto;
  }
}

.selectbox {
  background: $color-white;
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
    &.sizeM {
      width: 243px;
    }

    &.sizeS {
      width: 200px;
    }
  }
}

.input_textarea {
  position: relative;
  height: 120px;
}

.verticalTable_container {
  display: flex;
}

.marginL30 {
  margin-left: 30px;
}

.marginL12 {
  margin-left: 12px;
}

.width578 {
  width: 578px !important;
}

.textAreaWidth {
  width: 1000px;
}

.calendarWrapper {
  position: absolute;
  top: -15px;
  left: 80px;
}

.minWidth320 {
  min-width: 320px;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: $font-large-1;
  color: $color-default;
}

.box_right {
  display: flex;
  justify-content: end;
  gap: 10px;
  width: 85%;
}

.searchContainer {
  display: flex;
  gap: 8px;
}

.input_table_wrap {
  padding-top: 20px;
}
