@import '@assets/scss/style.module.scss';

.searchOptionArea {
  .innerBox + .innerBox {
    margin-left: 101px;
  }

  .option_detail {
    .btn {
      min-width: 80px !important;
    }
  }

  &.row {
    .flex {
      .inputWrap {
        width: 100%;
        flex: 1;
        margin-left: 10px;

        .input_text {
          width: 100%;
        }
      }
    }
  }
}

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.input_text {
  position: relative;
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}
