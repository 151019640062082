@import '@assets/scss/style.module.scss';

.searchOptionArea {
  margin-top: 20px;
  .flex {
    min-width: 75%;
    .sizeS {
      width: 160px;
      margin-right: 10px;
    }
  }
}

.title {
  margin: 10px 0;
}

.searchContainer {
  width: 60%;
}

.calendarContainer {
  min-width: 25%;
}
