@import '@assets/scss/style.module.scss';

.register_link {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  &.single {
    display: block;
    text-align: right;
  }
}

.note {
  margin-top: 10px;
  font-size: $font-small;
  color: #ff3723;
}
