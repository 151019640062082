.tableContainer {
}

.buttonBox {
  // display: flex;
  justify-content: end;
  gap: 10px;

  &.isCenter {
    justify-content: center;
  }
}
