@import '@assets/scss/style.module.scss';

.btn {
  min-width: 80px;
}

.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
}

.calc_table_wrap {
  position: relative;
  display: flex;
  gap: 10%;
  margin-top: 16px;

  .calc_table {
    position: relative;
    flex: 1;
    padding: 36px 32px 32px 32px;
    background: #efefef;

    &.single {
      flex: none;
      width: 28.3%;
    }

    .title {
      margin-bottom: 36px;
      .flex {
        justify-content: space-between;
        align-items: center;
      }
    }

    .double {
      flex: none;
      width: 50%;
    }

    .item {
      dl {
        display: flex;
        justify-content: space-between;
        &.total {
          margin-bottom: 22px;
        }
        &.detail {
          margin-top: 12px;
          dd {
            flex: 1 0 auto;
            text-align: right;
            color: #777;

            .addValue {
              position: relative;
              display: inline-block;
              min-width: 180px;
              padding-left: 30px;
              margin-left: 30px;
              color: #222;
            }
          }
        }
      }
    }
    .amount {
      margin-top: 22px;
      padding-top: 22px;
      border-top: 1px solid #aaa;
      dl {
        display: flex;
        justify-content: space-between;
      }
      dl + dl {
        margin-top: 12px;
      }
    }
  }
}

.flex {
  display: flex;
}

.mt10 {
  margin-top: 10px;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
}

.btn_adj_plus {
  width: 24px;
  height: 24px;
  font-size: 0;
  color: transparent;
  background-color: #fff;
  background: url('../../../../assets/img/btn_plus.svg') no-repeat;
  background-position: center;
}
.btn_adj_minus {
  width: 24px;
  height: 24px;
  font-size: 0;
  color: transparent;
  background-color: #fff;
  background: url('../../../../assets/img/btn_minus.svg') no-repeat;
  background-position: center;
}

.btn_adj_plus_disabled {
  opacity: 0.5;
  width: 24px;
  height: 24px;
  font-size: 0;
  color: transparent;
  background-color: #fff;
  background: url('../../../../assets/img/btn_plus.svg') no-repeat;
  background-position: center;
}
.btn_adj_minus_disabled {
  opacity: 0.5;
  width: 24px;
  height: 24px;
  font-size: 0;
  color: transparent;
  background-color: #fff;
  background: url('../../../../assets/img/btn_minus.svg') no-repeat;
  background-position: center;
}
