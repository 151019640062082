@import '@assets/scss/style.module.scss';

.calc_table_wrapper {
  margin-top: 32px;
  .titleArea {
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 26.7%;
      padding-bottom: 16px;
      border-bottom: 2px solid #222;
    }
    .tabMenu.tabMenu01.type02 {
      position: absolute;
      left: 36.7%;
      top: 0;
    }
  }
}
.calc_table_wrap {
  position: relative;
  display: flex;
  gap: 10%;
  margin-top: 16px;
  .equal {
    position: absolute;
    left: calc(33.33333% - 6.7%);
    top: 50%;
    z-index: 1;
    width: 10%;
    height: 16px;
    margin-top: -8px;
    background: url('../../../../assets/img/ico_equal.svg') no-repeat 50% 0;
  }
  .plus {
    position: absolute;
    right: calc(33.33333% - 6.7%);
    top: 50%;
    z-index: 1;
    width: 10%;
    height: 32px;
    margin-top: -16px;
    background: url('../../../../assets/img/ico_plus2.svg') no-repeat 50% 0;
  }
}
.calc_table {
  position: relative;
  flex: 1;
  padding: 36px 32px 32px 32px;
  background: #efefef;
  .title {
    margin-bottom: 36px;
    .flex {
      justify-content: space-between;
      align-items: center;
    }
    .nav {
      height: 32px;
      padding: 0 4px;
      border-radius: 16px;
      line-height: 32px;
      background: #fff;
      .dir {
        padding: 10px;
        font-size: 10px;
        line-height: 1;
        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
      .code {
        display: inline-block;
        padding: 0 24px;
        font-size: 14px;
      }
    }
  }
  .item {
    dl {
      display: flex;
      justify-content: space-between;
      &.total {
        margin-bottom: 22px;
      }
      &.detail {
        margin-top: 12px;
        dd {
          flex: 1 0 auto;
          text-align: right;
          color: #777;
          .addValue {
            position: relative;
            display: inline-block;
            min-width: 180px;
            padding-left: 30px;
            margin-left: 30px;
            color: $color-default;
            &:before {
              position: absolute;
              left: 0;
              top: 5px;
              content: '';
              display: block;
              width: 1px;
              height: 12px;
              background: #aaa;
            }
          }
        }
      }
    }
  }
  .amount {
    margin-top: 22px;
    padding-top: 22px;
    border-top: 1px solid #aaa;
    dl {
      display: flex;
      justify-content: space-between;
      & + dl {
        margin-top: 12px;
      }
    }
  }
  &.single {
    flex: none;
    width: calc(33.3% - 5%);
  }
  &.double {
    flex: none;
    width: 50%;
  }
}

.stepGuideText {
  position: relative;
  margin-top: 20px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
}

.selectbox {
  &.sizeM {
    width: 243px;
  }
  &.sizeS {
    width: 160px;
  }
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    cursor: pointer;
    border-radius: 8px;
  }
  &.mt10 {
    margin-top: 10px;
  }
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
    border-radius: 8px;
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    padding: 0px 12px;
    border-radius: 16px;
  }
  &.type01 {
    color: rgb(255, 255, 255);
    background: rgb(54, 54, 54);
  }
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
}
