@import '@assets/scss/style.module.scss';

.wrapper {
  min-height: calc(100vh - 125px - 48px);
  box-sizing: border-box;
  overflow: hidden auto;
}
.searchOptionArea {
  margin-top: 20px;

  .innerTitle {
    margin-right: 16px;
  }
}

.tableV8Layer {
  overflow: hidden auto;
  height: 250px;
  margin-top: 12px;
  border-top: 2px solid #222;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
  text-decoration: underline;
}

.messagePreview {
  width: 400px;
  padding: 30px;
  margin-top: 20px;
  white-space: pre-line;
  background: #eef4f6;
}
