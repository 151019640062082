@import '@assets/scss/style.module.scss';

.partner_list {
  display: flex;

  .guideContainer {
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      margin-top: 5px;
    }
  }

  .swiper {
    position: relative;
    overflow: hidden;
    width: calc(100% - 312px);
    margin-left: 20px;
  }

  .swiper-slide {
    // width: 340px;
    width: 29%;
    background: #ebf3f5;

    //Todo: 주석 처리로 인한 임시 스타일 (추후 제거 필요)
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: #cce5eb;
    }
    dl {
      padding: 24px 20px;
      dt {
        font-weight: $font-weight-bold;
        font-size: $font-large-1;
        & + dd {
          margin-top: 24px;
        }
      }
      dd {
        text-align: right;
      }
    }
  }

  .customPrevButton {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: url('../../../../assets/img/arr_next.svg') no-repeat;
    left: 0;
    margin-left: 12px;
    transform: rotate(180deg);

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  .customNextButton {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: url('../../../../assets/img/arr_next.svg') no-repeat;
    right: 0;
    margin-right: 12px;

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  // .nav {
  //   position: absolute;
  //   top: 50%;
  //   z-index: 1;
  //   width: 24px;
  //   height: 24px;
  //   margin-top: -12px;
  //   background: url("../../../../assets/img/arr_next.svg") no-repeat;
  //   &.swiper-button-prev {
  //     left: 0;
  //     margin-left: 12px;
  //     transform: rotate(180deg);
  //   }
  //   &.swiper-button-next {
  //     right: 0;
  //     margin-right: 12px;
  //   }
  // }
}
