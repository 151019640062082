@import '@assets/scss/style.module.scss';

.additionalInfo {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  font-size: #222;
  font-weight: $font-weight-medium;
  font-size: $font-small;
  span {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
}

.pointer_cursor {
  cursor: pointer;
}

.statusDashBoard {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .count {
    position: relative;
    width: 21.5%;
    padding: 24px 20px 8px;
    background: #ececec;
    text-align: right;
    .title {
      text-align: left;
      font-weight: $font-weight-bold;
    }
    .value {
      color: $color-primary;
      font-weight: $font-weight-bold;
      span {
        font-size: 56px;
      }
    }
    a.value {
      text-decoration: underline;
    }
    .type {
      position: absolute;
      right: 20px;
      top: 24px;
      display: flex;
      padding: 0 8px;
      border-radius: 12px;
      background: #fff;
      li {
        position: relative;
        margin-left: 5px;
        padding-left: 5px;
        font-size: $font-small;
        &:before {
          position: absolute;
          left: -5px;
          top: 3px;
          content: '';
          display: block;
          width: 1px;
          height: 12px;
          margin: 2px 5px 0;
          background: #ddd;
        }
        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
    &.total {
      background: #ececec url('../../../assets/img/ico_total.svg') no-repeat
        20px calc(100% - 25px);
    }
    &.sale {
      background: #ececec url('../../../assets/img/ico_sale.svg') no-repeat 20px
        calc(100% - 25px);
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    dl {
      flex: 1;
      padding: 24px 20px;
      background-color: #ebf3f5;
      dt {
        font-size: $font-large-1;
      }
      dd {
        position: relative;
        .valueWrap {
          display: flex;
          margin-top: 0;
          // @include pc-size-full {
          //   margin-top: 20px;
          // }
          .confirm {
            color: #165c61;
            &:before {
              content: '';
              display: inline-block;
              margin: 0 20px;
              width: 1px;
              height: 25px;
              background: #aaa;

              @include pc-size-full {
                margin: 0 5px;
              }
            }
          }
          &.type02 {
            margin-top: 32px;
            .value {
              font-size: 14px;
              .num {
                font-size: 32px;
              }
            }
          }
        }
        .value {
          font-weight: $font-weight-bold;
          color: #00aab7;
          @include pc-size-full {
            font-size: 14px;
          }
          .act {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
            background: $color-act-1;
            @include pc-size-full {
              width: 22px;
              height: 22px;
              vertical-align: text-bottom;
            }
          }
          .num {
            font-size: 56px;
          }
          .co_list_btn {
            position: relative;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-left: 10px;
            background: #fff url('../../../assets/img/ico_co_list.svg')
              no-repeat 50% 50%;
            font-size: 0;
            vertical-align: text-bottom;
            color: transparent;
            &:hover {
              .co_list {
                display: block;
              }
            }
          }
          .co_list {
            position: absolute;
            left: 50%;
            top: 47px;
            display: none;
            min-width: 170px;
            padding: 8px 16px;
            background: #fff;
            font-weight: $font-weight-regular;
            font-size: 14px;
            color: #222;
            transform: translateX(-50%);
            &:before {
              position: absolute;
              left: 50%;
              top: -4px;
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              margin-left: -5px;
              background: #fff;
              transform: rotate(45deg);
            }
          }
        }
        .detail {
          position: absolute;
          right: 0px;
          top: 28px;
          display: flex;
          padding: 4px 16px;
          border-radius: 20px;
          // background: #fff; seunghoon 실패 내역 조회
          background: #ebf3f5;
          li {
            position: relative;
            margin-left: 5px;
            padding-left: 5px;
            font-size: $font-small;
            &:before {
              position: absolute;
              left: -5px;
              top: 3px;
              content: '';
              display: block;
              width: 1px;
              height: 12px;
              margin: 2px 5px 0;
              background: #ddd;
            }
            &:first-child {
              margin-left: 0;
              padding-left: 0;
            }
            &:first-child:before {
              display: none;
            }
            span {
              font-weight: $font-weight-bold;
            }
            .ico_arr {
              position: relative;
              top: -2px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../../../assets/img/arr_right5.svg') no-repeat;
              vertical-align: middle;
            }
          }
        }
        .standard {
          text-align: right;
          font-size: $font-small;
          margin-top: 0;
          // @include pc-size-full {
          //   // margin-top: 30px;
          // }
        }
      }
    }
  }
}

.detail {
  position: absolute;
  right: 0px;
  top: 28px;
  display: flex;
  padding: 4px 16px;
  border-radius: 20px;
  background: #fff;
}

.steps {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  height: 90px;
  padding-left: 50px;
  background: #eee;
  font-size: $font-large-2;
  color: #999;
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    display: block;
    width: 56px;
    height: 90px;
    background: url(../../../assets/img/img_steps_normal.svg) no-repeat;
  }
  &:last-child:after {
    display: none;
  }
  &.on {
    background: #00aab7;
    color: #fff;
    &:after {
      background-image: url(../../../assets/img/img_steps_next_on2.svg);
    }
    &:before {
      position: absolute;
      left: -56px;
      top: 0;
      content: '';
      display: block;
      width: 56px;
      height: 90px;
      background: url(../../../assets/img/img_steps_next_on.svg) no-repeat;
    }
    &:first-child:before {
      display: none;
    }
    & + .on {
      &:before {
        background-image: url(../../../assets/img/img_steps_next_on3.svg);
      }
    }
    .badge {
      position: absolute;
      right: 56px;
      top: 28px;
      display: inline-block;
      height: 34px;
      padding: 0 12px;
      line-height: 34px;
      border-radius: 17px;
      background: #fff;
      font-size: $font-medium;
      font-weight: $font-weight-bold;
      color: #00aab7;
    }
  }
}

.stepGuideText {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
  .btn_container6 {
    position: absolute;
    right: 0;
    top: -6px;
    .btn {
      min-width: 120px;
      width: auto;
    }
  }
  & + .dataTable01 {
    margin-top: 16px;
  }
}

.dataTable01 {
  position: relative;
  margin-top: 22px;
  thead {
    tr:first-child {
      th {
        border-top: 2px solid $color-default;
      }
    }
    th {
      border-top: 1px solid #ccc;
      padding: 14px 0 14px;
      text-align: center;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    & + tbody {
      tr:first-child {
        td {
          border-top: 1px solid #ccc;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      border-bottom: 1px solid #ccc;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    tr {
      &:first-child {
        td {
          border-top: 2px solid $color-default;
        }
      }
    }
  }
  .totalCount {
    position: absolute;
    right: 0;
    top: -36px;
    margin-top: 0;
  }
}

.addCoupon {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  .box {
    flex: 1;
    padding: 16px 20px;
    background: #efefef;
    .flex {
      align-items: center;
    }
    .title {
      margin-right: 20px;
    }
    .btn_add {
      width: 120px;
    }
  }
  .input {
    .input_text {
      flex: 1 1 auto;
      max-width: 320px;
    }
    .btn_add {
      margin-left: 10px;
    }
    .note {
      padding-left: 80px;
    }
  }
  .file {
    position: relative;
    .attatchedFile {
      position: absolute;
      right: 180px;
      top: 20px;
      text-align: right;
      .fileName {
        margin-top: 12px;
        .delete {
          width: 20px;
          height: 20px;
          margin-left: 20px;
          background: url('../../../assets/img/btn_delete.svg') no-repeat;
          vertical-align: middle;
          font-size: 0;
          color: transparent;
        }
      }
    }
    .btn_add {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }
  .add {
    padding: 0;
    background: none;
    .flex {
      gap: 8px;
      padding-right: 180px;
    }
  }
  .list {
    position: relative;
    .totalCount {
      position: absolute;
      left: 20px;
      top: 42px;
    }
    .addCouponList {
      margin-left: 190px;
    }
  }
  & + .download_form {
    margin-top: 20px;
    padding-left: calc(50% + 10px);
    .btn {
      vertical-align: middle;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-size: $font-small;
    }
  }
}

.btn.btn01 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 48px;
  padding: 0 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
}

.btn.btn01.type03 {
  min-width: auto;
  padding: 0 14px;
  background: #555;
  color: #fff;
}

.btn.btn02.type01 {
  border: 1px solid #555;
}

.btn.btn02 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 24px;
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  color: #222;
}

.btn.btn02.type02 {
  padding: 0 12px;
  border: 1px solid #2e7d32;
  color: #2e7d32;
}

.btn.btn02 .ico_excel {
  display: inline-block;
  width: 16px;
  height: 15px;
  margin-right: 4px;
  vertical-align: middle;
  background: url('../../../assets/img/ico_excel.svg') no-repeat;
}

.btn.btn01.type01 {
  background: #222;
  color: #fff;
}

.btn .ico_next {
  display: inline-block;
  width: 8px;
  height: 14px;
  margin-left: 10px;
  vertical-align: middle;
  background: url('../../../assets/img/arr_right4.svg') no-repeat;
}

.desc {
  font-size: 15px;
}
