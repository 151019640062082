@import '@assets/scss/style.module.scss';

.contentBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .content {
    margin-right: 20px;
    z-index: 4;
  }

  .contentVertical {
    display: flex;
    gap: 10px;
  }
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
  text-align: left;
  margin-bottom: 20px;
}

.verticalTableFlex {
  display: flex;
  align-items: center;
}

.note {
  font-size: 14px;
  color: #767676;
  text-align: left;
}

.marginLeft {
  margin-left: 15px;
}

.fontLeft {
  text-align: left;
}

.marginTop {
  margin-top: 15px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 2px solid #222;
  padding-top: 15px;
}

.btn01 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 48px;
  padding: 0 20px;
  border-radius: 8px;
  text-align: center;
  font-size: $font-small;
  &.type01 {
    background: $color-default;
    color: $color-white;
  }
  &.type02 {
    background: $color-primary;
    color: $color-white;
  }

  &.type03 {
    background: #555;
    color: $color-white;
  }
}

.release_scope {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  .scope_item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    border-radius: 16px;
    line-height: 32px;
    background: #363636;
    color: $color-white;
    .delete {
      width: 20px;
      height: 20px;
      background: url('../../assets/img/btn_delete2.svg') no-repeat;
      font-size: 0;
      color: transparent;
    }
  }
}

.input_text input[type='text'],
.input_text input[type='password'] {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
  color: #222;
}

.popupSelect {
  width: 280px;
  margin-bottom: 20px;
  margin-left: 10px;
  z-index: 4;
}

.calendarContainer,
.popupContainer {
  display: flex;
  align-items: center;
}

.popupContainer {
  margin-right: 10px;
}

.verticalTableTitle {
  margin-top: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn_container {
  margin-top: 20px;
  .btn {
    flex: 1;
  }
}
.checkbox {
  margin: 5px 30px 0px 0px;
}
