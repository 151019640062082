@import '@assets/scss/style.module.scss';

.pageTitle {
  font-size: 20px;
}

.statusDashBoard {
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .count {
    position: relative;
    width: 21.5%;
    padding: 24px 20px 8px;
    background: #ececec;
    text-align: right;
    .title {
      text-align: left;
      font-weight: $font-weight-bold;
    }
    .value {
      color: $color-primary;
      font-weight: $font-weight-bold;
      span {
        font-size: 56px;
      }
    }
  }
  .sales_count {
    display: flex;
    align-items: center;
    width: 40%;
    height: 132px;
    padding: 0 20px;
    background: #ebf3f5;
    dl {
      display: flex;
      align-items: center;
    }
    .case {
      dt {
        font-size: 20px;
        .date {
          display: block;
          font-size: 12px;
          color: #767676;
        }
      }
      dd {
        margin-left: 12px;
        font-size: 14px;
        font-weight: bold;
        color: #00aab7;
        .num {
          font-size: 32px;
        }
      }
    }
    .detail_count_wrap {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid #aaa;
    }
    .detail_count {
      font-size: 14px;
      color: #165c61;
      font-weight: bold;
      dd {
        display: flex;
        .amount {
          min-width: 142px;
          text-align: right;
        }
        .num {
          font-size: 20px;
        }
      }
    }
  }
  .taxBill {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    width: 20%;
    height: 132px;
    padding: 0 20px;
    background: #ebf3f5;
    dl {
      width: 100%;
    }
    dt {
      font-size: 20px;
    }
    dd {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
      font-weight: bold;
      font-size: 14px;
      color: #165c61;
    }
  }
  .detail_count_wrap {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #aaa;
  }
  .detail_count {
    font-size: 14px;
    color: #165c61;
    font-weight: bold;
    dd {
      display: flex;
      .amount {
        min-width: 142px;
        text-align: right;
      }
      .num {
        font-size: 20px;
      }
    }
  }
}

.stepsFlow {
  display: flex;
  justify-content: space-between;

  .steps {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    font-weight: 700;
    line-height: 32px;
    color: rgb(153, 153, 153);
    flex: 1 1 0%;
    padding: 12px 60px 12px 50px;
    height: 100% !important;
  }
  .type03 {
    width: 100%;
  }
  .steps.on {
    color: rgb(255, 255, 255);
    background: rgb(0, 170, 183);
  }
  .steps::after {
    position: absolute;
    right: 0px;
    top: 0px;
    content: '';
    display: block;
    width: 35px;
    height: 100% !important;
    background: url(../../../../assets/img/img_steps_normal.svg) 0% 0% / 100%
      100% no-repeat;
  }
  .steps.on::before {
    position: absolute;
    left: -28px;
    top: 0px;
    content: '';
    display: block;
    width: 28px;
    height: 100% !important;
  }
}
