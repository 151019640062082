@import '@assets/scss/style.module.scss';

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%; /* 화면 높이에 맞게 설정 */
  .table_header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .box_left {
      display: flex;
      align-items: center;
      .total_count {
        span {
          color: $color-pink;
        }
      }
      .selectbox {
        width: 72px;
        margin-left: 16px;
      }
    }
    .box_right {
      .btn {
        width: 80px;
      }
    }
  }
  .checkBoxTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 36px;
    .thead {
      padding: 10px;
      border-top: 2px solid #222;
      height: 53.6px;
      position: sticky;
      top: 0;
      left: 0;
      background-color: #f9f9f9;

      .tableList {
        border-bottom: 1px solid #ccc;
        .table_th {
          padding: 20.2px;
          min-height: 53.6px;
        }
      }
    }
    .tbody_tr {
      min-height: 53.6px;
      border-bottom: 1px solid #ccc;
      .tbody_td {
        text-align: center;
        padding: 16.4px;
      }
    }
  }
  .table_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0px;
    .excelPosition {
      margin-left: 1500px;
    }
  }
  // TODO: 임시로 푸터 추가(준형)
  .table_footer_test {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .excelPosition {
      margin-left: 1500px;
    }
  }
}

.btn {
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;

    &.type04 {
      min-width: 60px;
      height: 32px;
      padding: 0 10px;
      background: #363636;
      color: white;
    }

    &:disabled {
      background: $color-bg-2;
      color: $color-white;
      cursor: default;
    }
  }
  &.btn02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    &.type04 {
      padding: 0 12px;
      border: 1px solid #555;
      color: $color-default;
    }
  }

  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    &.type02 {
      background: #00aab7;
      color: #fff;
    }
  }
}

.btn_txt04 {
  display: inline-block;
  text-decoration: underline;
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 16px;
  .pageIndex {
    margin-left: 15px;
  }
}

.pagination .nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-size: 0;
  color: transparent;
}
.pagination .nav:after {
  content: '';
  display: block;
  width: 14px;
  height: 12px;
}
.pagination .nav.first {
  margin-right: 6px;
}
.pagination .nav.first:after {
  transform: rotate(180deg);
  background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
}
.pagination .nav.prev:after {
  transform: rotate(180deg);
  background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
}
.pagination .nav.next:after {
  background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
}
.pagination .nav.last {
  margin-left: 6px;
}
.pagination .nav.last:after {
  background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
}
.pagination .nav:disabled {
  background: #f4f4f4;
}
.pagination .nav:disabled:after {
  opacity: 0.2;
}
.pagination .order {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 12px;
}
.pagination .order li {
  margin: 0 12px;
}
.pagination .order li button {
  font-size: 14px;
}
.pagination .order li.on button {
  font-weight: 700;
}
.pageIndexInfo {
  margin-left: 15px;
}

.noMargin {
  margin: 0 !important;
  &.thead {
    border-top: none !important;
  }
}
