@import '@assets/scss/style.module.scss';

.selectbox {
  background-color: $color-white;

  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;

    .label {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: inherit;
      border: 1px solid $color-border;
      padding-left: 15px;
      border-radius: 8px;
      padding-right: 45px;
      font-size: 16px;
      white-space: nowrap;
      cursor: pointer;

      border: 1px solid $color-border;
      padding-left: 15px;
      border-radius: 8px;
      font-size: $font-medium;
      cursor: pointer;

      &:after {
        position: absolute;
        right: 16px;
        top: 13px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('../../assets/img/arr_selectbox.svg') 50% 50% no-repeat;
      }

      .icon {
        position: absolute;
        right: 16px;
        top: 13px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;

        .up {
          top: 17px;
          transform: rotate(180deg);
        }
      }
    }

    .optionList {
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 1;
      display: none;
      width: 100%;
      border-radius: 0 0 8px 8px;
      background: $color-white;
      color: $color-gray-3;
      overflow: hidden;

      &.disabled {
        display: none !important;
        height: 0px !important;
      }

      &.hasScroll {
        height: 380px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #e4e4e4;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: darkgray;
          border-radius: 100px;
          box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
          border-radius: 100px;
        }
      }

      &.showOption {
        display: block;
      }

      .optionItem {
        padding: 9px 16px;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;

        &.hasScroll {
          border-right: 0px;
        }

        &:hover {
          background: $color-bg-4;
        }

        &:first-child {
          border-top: 1px solid $color-border;
        }

        &:last-child {
          border-radius: 0 0 8px 8px;
          border-bottom: 1px solid $color-border;
        }
      }
    }

    &.active {
      z-index: 2;

      .label {
        border-color: $color-default;
        border-radius: 8px 8px 0 0;

        &:after {
          top: 17px;
          transform: rotate(180deg);
        }
      }

      .optionList {
        display: block;

        .optionItem {
          border-color: $color-default;

          &:hover {
            color: $color-default;
          }
        }
      }
    }
  }
  // &.type02 {
  //   position: relative;
  //   width: 170px;
  //   height: 36px;
  //   border-radius: 8px;
  //   cursor: pointer;
  //   .ico {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     z-index: 1;
  //     width: 36px;
  //     height: 36px;
  //     &.ico_user {
  //       background: url('../../assets/img/ico_user.svg') no-repeat 0 0 / 36px;
  //     }
  //   }
  //   .label {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: relative;
  //     width: 100%;
  //     height: 36px;
  //     padding: 0 44px 0 56px;
  //     border-radius: 18px;
  //     background: $color-bg-8;
  //     text-align: left;
  //     font-size: $font-small;
  //     &:after {
  //       position: absolute;
  //       right: 12px;
  //       top: 6px;
  //       content: '';
  //       display: block;
  //       width: 24px;
  //       height: 24px;
  //       background: url('../../assets/img/arr_selectbox2.svg') no-repeat;
  //     }
  //   }
  //   .optionList {
  //     position: absolute;
  //     left: 0;
  //     top: 36px;
  //     z-index: 1;
  //     display: none;
  //     width: 100%;
  //     padding: 0 12px 0 16px;
  //     border-radius: 0 0 8px 8px;
  //     background: $color-bg-8;
  //     overflow: hidden;
  //     .optionItem {
  //       padding: 10px 0;
  //       line-height: 1;
  //       font-size: $font-small;
  //     }
  //   }
  //   &.active {
  //     .label {
  //       border-radius: 18px 18px 0 0;
  //     }
  //     .optionList {
  //       display: block;
  //     }
  //   }
  // }
  // &.type03 {
  //   position: relative;
  //   width: 150px;
  //   height: 32px;
  //   border-radius: 16px;
  //   cursor: pointer;
  //   .label {
  //     display: flex;
  //     align-items: center;
  //     position: relative;
  //     width: 100%;
  //     height: 32px;
  //     padding: 0 34px 0 12px;
  //     border-radius: 16px;
  //     background: $color-bg-8;
  //     text-align: left;
  //     font-size: $font-small;
  //     &:after {
  //       position: absolute;
  //       right: 12px;
  //       top: 13px;
  //       content: '';
  //       display: block;
  //       width: 10px;
  //       height: 6px;
  //       background: url('../../assets/img/arr_selectbox3.svg') no-repeat;
  //     }
  //   }
  //   .optionList {
  //     position: absolute;
  //     left: 0;
  //     top: 32px;
  //     z-index: 1;
  //     display: none;
  //     width: 100%;
  //     padding: 0 12px;
  //     border-radius: 0 0 16px 16px;
  //     background: $color-bg-8;
  //     overflow: hidden;
  //     .optionItem {
  //       padding: 8px 0;
  //       line-height: 1;
  //       font-size: $font-small;
  //     }
  //   }
  //   &.active {
  //     .label {
  //       border-radius: 16px 16px 0 0;
  //     }
  //     .optionList {
  //       display: block;
  //     }
  //   }
  // }
  &.type04 {
    position: relative;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    .label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 32px;
      padding: 0 34px 0 12px;
      border-radius: 12px;
      border: 1px solid $color-border-bottom;
      background: $color-white;
      text-align: left;
      font-size: $font-small;
      @include textEllipsis;
      &:after {
        position: absolute;
        right: 12px;
        top: 4px;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url('../../assets/img/arr_selectbox2.svg') no-repeat;
      }
    }
    .optionList {
      position: absolute;
      left: 0;
      top: 32px;
      z-index: 1;
      display: none;
      width: 100%;
      border-radius: 0 0 8px 8px;
      background: $color-white;
      overflow: hidden;

      &.disabled {
        display: none !important;
        height: 0px !important;
      }

      &.hasScroll {
        height: 265px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #e4e4e4;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: darkgray;
          border-radius: 100px;
          box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
          border-radius: 100px;
        }
      }

      .optionItem {
        padding: 8px 12px;
        border-left: 1px solid $color-border-bottom;
        border-right: 1px solid $color-border-bottom;
        line-height: 1;
        font-size: $font-small;

        &.hasScroll {
          border-right: 0px;
        }

        &:last-child {
          border-radius: 0 0 8px 8px;
          border-bottom: 1px solid $color-border-bottom;
        }
      }
    }
    &.active {
      .label {
        border-radius: 16px 16px 0 0;
      }
      .optionList {
        display: block;
      }
    }
  }
}

.disabled {
  background: $color-bg-4 !important;
  color: $color-bg-1 !important;

  .label {
    border-radius: 16px !important;

    &:after {
      display: none !important;
    }
  }
}
