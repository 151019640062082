@import '@assets/scss/style.module.scss';

.stepsFlow {
  display: flex;
  justify-content: space-between;
  .steps {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    height: 90px;
    padding-left: 50px;
    background: #eee;
    font-size: $font-large-2;
    color: #999;
    &:after {
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      display: block;
      width: 56px;
      height: 90px;
      background: url('../../../assets/img/img_steps_normal.svg') no-repeat;
    }
    &:last-child:after {
      display: none;
    }
    &.on {
      background: #00aab7;
      color: #fff;
      &:after {
        background-image: url('../../../assets/img/img_steps_next_on2.svg');
      }
      &:before {
        position: absolute;
        left: -56px;
        top: 0;
        content: '';
        display: block;
        width: 56px;
        height: 90px;
        background: url('../../../assets/img/img_steps_next_on.svg') no-repeat;
      }
      &:first-child:before {
        display: none;
      }
      & + .on {
        &:before {
          background-image: url('../../../assets/img/img_steps_next_on3.svg');
        }
      }
      .badge {
        position: absolute;
        right: 56px;
        top: 28px;
        display: inline-block;
        height: 34px;
        padding: 0 12px;
        line-height: 34px;
        border-radius: 17px;
        background: #fff;
        font-size: $font-medium;
        font-weight: $font-weight-bold;
        color: #00aab7;
      }
    }
  }
  &.type02 {
    .steps {
      padding-left: 1%;
    }
  }
}

.stepGuideText {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
  .btn_container6 {
    position: absolute;
    right: 0;
    top: -6px;
    .btn {
      min-width: 120px;
      width: auto;
    }
  }
  & + .dataTable01 {
    margin-top: 16px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
  text-decoration: underline;
}
.messagePreview {
  width: 400px;
  padding: 30px;
  margin-top: 20px;
  white-space: pre-line;
  background: #eef4f6;
}

.btn_wrapper {
  margin-top: 10px;
}
