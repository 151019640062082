// /* FONT-WEIGHTS */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// COLORS
$color-primary: #00aab7;
$color-secondary: #a50034;

$color-negative: #ff3723;
$color-positive: #76892d;
// $color-neutral      :#21a9aa;

$color-default: #222;
$color-gray-1: #333;
$color-gray-2: #666;
$color-gray-3: #767676;
$color-white: #fff;
$color-green: #165c61;
$color-pink: #f65f8d;
$color-red: #c30036;

$color-act-1: #ff5b5b;
$color-act-2: #fe9f3f;
$color-act-3: #50dc75;
$color-act-4: #9650dc;

$color-disable: #959595;
$color-border: #ddd;
$color-border-bottom: #555;

$color-bg-1: #aaa;
$color-bg-2: #ccc;
$color-bg-3: #ddd;
$color-bg-4: #eee;
$color-bg-5: #f5f5f5;
$color-bg-6: #ebf3f5;
$color-bg-7: #ffe6e6;
$color-bg-8: #efefef;

// font size
$font-x-small: 12px;
$font-small: 14px;
$font-medium: 16px;
$font-large: 18px;
$font-large-1: 20px;
$font-large-2: 24px;
$font-large-3: 28px;
$font-large-4: 32px;
$font-large-5: 40px;
$font-large-6: 48px;

// media query
$desktop-full-width: 1920px;
$desktop-l-width: 1700px;
$desktop-ml-width: 1600px;
$desktop-m-width: 1460px;
$desktop-s-width: 1284px;
$tablet-l-width: 1024px;
$tablet-s-width: 767px;
$mobile-s-width: 360px;
