@import '@assets/scss/style.module.scss';

.registerWrap {
  background: #f9f9fa;
}
.registerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 360px;
  margin: 0 auto;

  &.isLoginForm {
    height: calc(100vh - 140px);
  }

  .pageTitle2 {
    text-align: center;
    font-size: $font-large-2;

    &.hasTitleMargin {
      margin-top: 80px;
    }
  }

  .register_link {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    &.single {
      display: block;
      text-align: right;
    }
  }
}
.register_input {
  margin-top: 60px;
}

.register_footer {
  display: flex;
  height: 140px;
  align-items: center;
  justify-content: center;
  min-width: 1280px;

  .copyright {
    display: inline-block;
    min-height: 24px;
    line-height: 24px;
    padding-left: 32px;
    background: url('../../../../assets/img/logo.svg') no-repeat 0 50% / 24px;
    font-weight: $font-weight-bold;
    color: #767676;
  }
}

.input_text {
  position: relative;
  input[type='text'],
  input[type='password'] {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background: $color-white;
    font-size: $font-medium;
    color: $color-default;
    &:focus {
      border-color: $color-default;
    }
    &::placeholder {
      color: #aaa;
    }
    &:read-only {
      background: $color-bg-4;
      color: #aaa;
    }
    &:read-only:focus {
      border-color: #ddd;
    }
  }
}

.inputWrap + .inputWrap {
  margin-top: 10px;
}
