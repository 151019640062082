@import '@assets/scss/style.module.scss';

/* 온보딩 */

.layerPopupWrap {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.dimmed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.popup_title_center {
  display: block;
  text-align: center;
  font-size: $font-large-1;
  color: $color-default;
}

.article_container {
  overflow: hidden;
  max-width: 1280px;
  text-align: center;
  .indicator {
    margin-top: 36px;
  }
  .btn_container4 {
    margin-top: 48px;
  }
}

.txt {
  height: 75px;
  margin-top: 48px;
  font-size: 24px;
  color: #767676;
  span {
    display: block;
    margin-top: 16px;
    font-size: 20px;
    color: $color-default;
  }
}

.type05 {
  min-width: 180px;
  height: 48px;
  padding: 0 10px;
  border: 1px solid #707070;
  background: #fff;
  font-weight: 500;
}
