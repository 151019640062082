@import '@assets/scss/style.module.scss';

.container {
  height: 32px;
  padding: 0 4px;
  border-radius: 16px;
  line-height: 32px;
  background: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;

  .button {
    position: relative;
    z-index: 3;
    padding: 10px;
    font-size: 10px;
    line-height: 1;
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  .code {
    display: inline-block;
    padding: 0 24px;
    font-size: 14px;
  }
}

.container.slide-default {
  text-align: center;
}

.container.slide-left {
  transform: translateX(-10px);
  text-align: center;
}

.container.slide-right {
  transform: translateX(10px);
  text-align: center;
}
