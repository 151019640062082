@import '@assets/scss/style.module.scss';

.searchOptionArea {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: #efefef;
}

.mt20 {
  margin-top: 20px;
}

.title {
  margin-right: 16px;
  font-weight: 400;
  white-space: noWrap;
}

.innerBox {
  display: flex;
  align-items: center;
}
