@charset "utf-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
  line-height: 1.4;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 400;
  word-break: keep-all;
  word-wrap: break-word;
}
//팝업 스크롤 제거용 
#root{
  height:100vh
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}
form {
  margin: 0;
  padding: 0;
}
pre {
  white-space: inherit;
}
button {
  background-color: transparent;
  cursor: pointer;
  border-width: 0;
  font-size: 16px;
  color: #111;
}
fieldset,
iframe {
  width: 100%;
}
img,
a,
frame,
iframe,
fieldset {
  border: 0;
}

a {
  text-decoration: none;
  color: #111;
}
a[href^='tel'] {
  /* color: inherit;  */
  text-decoration: none;
}
img {
  border: 0;
  vertical-align: top;
}
i,
em,
address {
  font-style: normal;
  line-height: 140%;
}
table {
  width: 100%;
  padding: 0;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
th,
td {
  padding: 0;
}
caption {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  font-size: 0;
}
input {
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}
input,
textarea,
select,
button {
  font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
}
form legend {
  display: none;
}

.blind {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  font-size: 0;
}
.hidden {
  overflow: hidden;
  display: inline-block;
  position: relative;
  z-index: -1;
  border: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
strong {
  line-height: 140%;
  margin: 0;
  word-break: keep-all;
}
ul,
dl {
  line-height: 140%;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* ios 길게터치시 highlighting 막는 style */
body {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

[tabindex],
[tabindex]:focus {
  outline: none;
}

a:focus,
input:focus,
button:focus {
  outline: 0;
}

a:focus-visible,
button:focus-visible {
  outline: 0;
}

* textarea {
  resize: none !important;
}
