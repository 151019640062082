@import '@assets/scss/style.module.scss';

.ico_act {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;

  &.type03 {
    background: #50dc75;
  }

  &.type01 {
    background: #ff5b5b;
  }
}

.btn {
  vertical-align: middle;
  margin-right: 5px;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
    border-radius: 8px;
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    padding: 0px 12px;
    border-radius: 16px;
  }
  &.type01 {
    color: rgb(255, 255, 255);
    background: rgb(54, 54, 54);
  }
}

.stepGuideText {
  position: relative;
  margin-top: 20px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
}

.ico_download {
  display: inline-block;
  width: 14px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  background: url(../../../../assets/img/ico_download.svg) no-repeat;
}
