@import '@assets/scss/style.module.scss';

.color_primary {
  color: #00aab7;
}

.popupWrap {
  width: 554px;
}

.alert_body {
  white-space: pre-wrap;
  text-align: center;
}
