@import '@assets/scss/style.module.scss';

.bgBlack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.75);
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 20px 10px;
  overflow: hidden;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-height: 770px;

  &.big {
    width: 1440px;
    height: 770px;
  }
  &.small {
    width: 660px;
    height: 330px;
  }

  .contentLayer {
    // background-color: aqua;
    width: 100%;
    height: 100%;
    padding: 20px;

    // scroll custom design
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
      border-radius: 100px;
      box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
      border-radius: 100px;
    }
  }
}

.buttonLayer {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .button {
    background-color: #00aab7;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    width: 180px;

    &.default {
      background-color: #555 !important;
    }
  }
}
