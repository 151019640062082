@import '@assets/scss/style.module.scss';

.selectBoxContainer {
  margin-top: 10px;
}

.pageTitle {
  font-size: 20px;
}

.statusDashBoard {
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .adjusmentStatus {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    dl {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 28px 20px;
      background: #ebf3f5;

      dt {
        text-align: center;
        .type {
          display: block;
          margin-top: 8px;
          font-size: 12px;
          color: #767676;
        }
      }

      ul {
        margin-left: 40px;

        .type {
          display: inline-block;
          min-width: 120px;
          font-size: 14px;
          font-weight: bold;
          color: #165c61;
        }
      }
      .num {
        display: inline-block;
        min-width: 20px;
      }
    }
  }
  .sales_count {
    display: flex;
    align-items: center;
    width: 40%;
    min-height: 122px;
    padding: 0 20px;
    background: #ebf3f5;
    dl {
      display: flex;
      align-items: center;
    }
  }
}

.ico_act {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;

  &.type03 {
    background: #50dc75;
  }

  &.type01 {
    background: #ff5b5b;
  }
}

.stepsFlow {
  display: flex;
  justify-content: space-between;

  &.type03 {
    width: 100%;
  }
  &.mt32 {
    margin-top: 32px;
  }

  .steps {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 12px 60px 12px 50px;
    background: #eee;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
    color: #999;
    height: 100% !important;

    &.on {
      background: #00aab7;
      color: #fff;
    }
  }
  .steps::after {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    display: block;
    width: 28px;
    height: 100% !important;
    background: url(../../../assets/img/img_steps_normal.svg) no-repeat;
    background-size: 100% 100%;
  }
  .steps.on::before {
    position: absolute;
    left: -28px;
    top: 0;
    content: '';
    display: block;
    width: 28px;
    height: 100% !important;
    background: url(../../../assets/img/img_steps_next_on.svg) no-repeat;
    background-size: 100% 100%;
  }
  .steps.on + .on:before {
    background-image: url(../../../assets/img/img_steps_next_on3.png);
  }
}

.btn {
  vertical-align: middle;
  margin-right: 5px;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
    border-radius: 8px;
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    padding: 0px 12px;
    border-radius: 16px;
  }
  &.type01 {
    color: rgb(255, 255, 255);
    background: rgb(54, 54, 54);
  }
}
