@import '@assets/scss/style.module.scss';

.pointer {
  cursor: pointer;
  text-decoration: underline;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
  text-align: left;
}

.popup_body {
  margin-top: 32px;
}

.text_box1 {
  padding: 14px;
  border-radius: 8px;
  background: #eee;
  border: 1px solid #ddd;
}

.xmp {
  white-space: pre-line;
}
