@import '@assets/scss/style.module.scss';

.mainTitle {
  margin-top: 20px;
  font-size: 18px;

  &:first-child {
    margin-top: 0;
  }
}

.tableLayer {
  margin-top: 22px;

  .colHead {
    width: 17%;
  }
  .colBody {
    width: 33%;
  }

  tbody {
    tr {
      margin: 0;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;

      &:first-child {
        border-top: 2px solid #222;
      }
    }
    th,
    td {
      padding: 12px 0 12px 32px;
      text-align: left;
    }
    th {
      background-color: #efefef;
    }
  }
}

.tableLayerColumn {
  margin-top: 12px;

  th,
  td {
    padding: 8px 0;
    text-align: center;
  }

  thead {
    border-top: 2px solid #222;
  }
  tbody {
    tr {
      &:first-child {
        border-top: 1px solid #ccc;
      }
      border-bottom: 1px solid #ccc;
    }
  }
}
