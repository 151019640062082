@import '@assets/scss/style.module.scss';

.partner_list {
  display: flex;

  .guideContainer {
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      margin-top: 5px;
    }
  }

  .confirmRequest {
    position: relative;
    width: 20%;
    padding: 24px 20px;
    margin-left: 20px;
    background: #eee;
    dt {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    dd {
      color: #00aab7;
    }
    .num {
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
    .ico_act_type01 {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 6px;
      border-radius: 50%;
      background: #ff5b5b;
    }
    .btnShortcut {
      position: absolute;
      right: 20px;
      bottom: 35px;
      padding: 4px 12px;
      border-radius: 11px;
      background: #fff;
      font-size: 12px;
    }
  }

  .swiper {
    position: relative;
    overflow: hidden;
    width: calc(100% - 312px);
    margin-left: 20px;
  }

  .swiper-slide {
    // width: 340px;
    width: 29%;
    background: #ebf3f5;
    &.active {
      background: #cce5eb;
    }
    &.disabled {
      background: #ececec;
      opacity: 0.3;
    }
    .card {
      width: 100%;
      height: 100%;
      dt {
        font-size: 20px;
      }
      dd {
        margin-top: 20px;
        font-weight: bold;
        color: #00aab7;
      }
      .num {
        font-size: 32px;
      }
    }
    // dl {
    //   padding: 24px 20px;
    //   dt {
    //     font-weight: $font-weight-bold;
    //     font-size: $font-large-1;
    //     & + dd {
    //       margin-top: 24px;
    //     }
    //   }
    //   dd {
    //     text-align: right;
    //   }
    // }
  }

  .customPrevButton {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: url('../../../../assets/img/arr_next.svg') no-repeat;
    left: 0;
    margin-left: 12px;
    transform: rotate(180deg);

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  .customNextButton {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: url('../../../../assets/img/arr_next.svg') no-repeat;
    right: 0;
    margin-right: 12px;

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  // .nav {
  //   position: absolute;
  //   top: 50%;
  //   z-index: 1;
  //   width: 24px;
  //   height: 24px;
  //   margin-top: -12px;
  //   background: url("../../../../assets/img/arr_next.svg") no-repeat;
  //   &.swiper-button-prev {
  //     left: 0;
  //     margin-left: 12px;
  //     transform: rotate(180deg);
  //   }
  //   &.swiper-button-next {
  //     right: 0;
  //     margin-right: 12px;
  //   }
  // }
}
