@import '@assets/scss/style.module.scss';

.datePicker {
  width: 120px;
  height: 48px;
  padding: 0 16px !important;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
  color: #222;
}

.calIcon {
  cursor: pointer;
  left: 115px;
}
