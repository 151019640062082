@import '@assets/scss/style.module.scss';

.pageTitle {
  margin-top: 24px;
  font-size: $font-large-2;
}

.flowChart {
  padding: 20px;
  background: #efefef;
  .title {
    margin-bottom: 16px;
  }
  .img {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}

.selectbox {
  background: $color-white;
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
    &.sizeS {
      min-width: 160px;
    }
  }
}

.marginL4 {
  margin-left: 4%;
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
    &.type03 {
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
  }
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;
    &.type01 {
      background: #363636;
      color: $color-white;
    }
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
  }
}

.box_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
