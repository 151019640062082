@import '@assets/scss/style.module.scss';

.statusDashBoard {
  .color_primary {
    color: $color-primary;
  }
  .color_pink {
    color: $color-pink;
  }

  .underline {
    text-decoration: underline;
  }

  .status.step {
    gap: 0;

    dl {
      position: relative;
      padding-left: 5%;

      dt {
        font-size: 16px;
        font-weight: 700;
      }

      &:before {
        position: absolute;
        left: -15px;
        top: 50%;
        content: '';
        display: block;
        width: 30px;
        height: 24px;
        margin-top: -5px;
        background: url('../../../assets/img/ico_next.svg') no-repeat;
      }

      &:first-of-type:before {
        display: none;
      }
    }
  }

  .reject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    background: #ffe6e6;
    min-width: 16%;
    padding: 0 2.5%;

    dl {
      dt {
        flex: 1 0 auto;
        min-width: 50px;
        font-weight: 700;
        font-size: 16px;
      }

      dd {
        font-weight: 700;

        .value {
          color: #f65f8d;

          .num {
            font-size: 56px;
            line-height: 1;
          }
        }
      }
    }
  }
}
