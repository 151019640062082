@import '@assets/scss/style.module.scss';

.ml20 {
  margin-left: 20px;
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}

.type01 {
  min-width: 136px;
}
