@import '@assets/scss/style.module.scss';

.container {
  display: inline-block;
  align-items: center;
  transition: transform 0.3s ease-in-out;

  .button {
    width: 24px;
    height: 24px;
    background: url('../../assets/img/arr_left.svg') no-repeat;
    vertical-align: middle;
    font-size: 0;
    color: transparent;
    &.next {
      transform: rotate(180deg);
    }
  }
  .date {
    display: inline-block;
    margin: 0 12px;
    vertical-align: middle;
  }
  .btn_calendar {
    width: 24px;
    height: 24px;
    margin-left: 12px;
    background: url('../../assets/img/ico_calendar.svg') no-repeat 50% 50% /
      24px;
    font-size: 0;
    color: transparent;
    vertical-align: middle;
  }
}

.container.slide-default {
  width: 140px;
  text-align: center;
}

.container.slide-left {
  transform: translateX(-10px);
  width: 140px;
  text-align: center;
}

.container.slide-right {
  transform: translateX(10px);
  width: 140px;
  text-align: center;
}

.calendarIcon {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}
