@import '@assets/scss/style.module.scss';

.statusDashBoard {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .count {
    position: relative;
    width: 21.5%;
    padding: 24px 20px 8px;
    background: #ececec;
    text-align: right;
    .title {
      text-align: left;
      font-weight: $font-weight-bold;
    }
    .value {
      color: $color-primary;
      font-weight: $font-weight-bold;
      span {
        font-size: 56px;
      }
    }
    a.value {
      text-decoration: underline;
    }
    .type {
      position: absolute;
      right: 20px;
      top: 24px;
      display: flex;
      padding: 0 8px;
      border-radius: 12px;
      background: #fff;
      li {
        position: relative;
        margin-left: 5px;
        padding-left: 5px;
        font-size: $font-small;
        &:before {
          position: absolute;
          left: -5px;
          top: 3px;
          content: '';
          display: block;
          width: 1px;
          height: 12px;
          margin: 2px 5px 0;
          background: #ddd;
        }
        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
    &.total {
      background: #ececec url('../../../assets/img/ico_total.svg') no-repeat
        20px calc(100% - 25px);
    }
    &.sale {
      background: #ececec url('../../../assets/img/ico_sale.svg') no-repeat 20px
        calc(100% - 25px);
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    dl {
      flex: 1;
      padding: 24px 20px;
      background-color: #ebf3f5;
      dt {
        font-size: $font-large-1;
      }
      dd {
        position: relative;
        .valueWrap {
          display: flex;
          margin-top: 0;
          // @include pc-size-full {
          //   margin-top: 20px;
          // }
          .confirm {
            color: #165c61;
            &:before {
              content: '';
              display: inline-block;
              margin: 0 20px;
              width: 1px;
              height: 25px;
              background: #aaa;

              @include pc-size-full {
                margin: 0 5px;
              }
            }
          }
          &.type02 {
            margin-top: 32px;
            .value {
              font-size: 14px;
              .num {
                font-size: 32px;
              }
            }
          }
        }
        .value {
          font-weight: $font-weight-bold;
          color: #00aab7;
          @include pc-size-full {
            font-size: 14px;
          }
          .act {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
            background: $color-act-1;
            @include pc-size-full {
              width: 22px;
              height: 22px;
              vertical-align: text-bottom;
            }
          }
          .num {
            font-size: 56px;
          }
          .co_list_btn {
            position: relative;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-left: 10px;
            background: #fff url('../../../assets/img/ico_co_list.svg')
              no-repeat 50% 50%;
            font-size: 0;
            vertical-align: text-bottom;
            color: transparent;
            &:hover {
              .co_list {
                display: block;
              }
            }
          }
          .co_list {
            position: absolute;
            left: 50%;
            top: 47px;
            display: none;
            min-width: 170px;
            padding: 8px 16px;
            background: #fff;
            font-weight: $font-weight-regular;
            font-size: 14px;
            color: #222;
            transform: translateX(-50%);
            &:before {
              position: absolute;
              left: 50%;
              top: -4px;
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              margin-left: -5px;
              background: #fff;
              transform: rotate(45deg);
            }
          }
        }
        .detail {
          position: absolute;
          right: 0px;
          top: 28px;
          display: flex;
          padding: 4px 16px;
          border-radius: 20px;
          background: #fff;
          li {
            position: relative;
            margin-left: 5px;
            padding-left: 5px;
            font-size: $font-small;
            &:before {
              position: absolute;
              left: -5px;
              top: 3px;
              content: '';
              display: block;
              width: 1px;
              height: 12px;
              margin: 2px 5px 0;
              background: #ddd;
            }
            &:first-child {
              margin-left: 0;
              padding-left: 0;
            }
            &:first-child:before {
              display: none;
            }
            span {
              font-weight: $font-weight-bold;
            }
            .ico_arr {
              position: relative;
              top: -2px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../../../assets/img/arr_right5.svg') no-repeat;
              vertical-align: middle;
            }
          }
        }
        .standard {
          text-align: right;
          font-size: $font-small;
          margin-top: 0;
          //   @include pc-size-full {
          //     margin-top: 30px;
          //   }
        }
      }
    }
  }
}

.input_check {
  input[type='checkbox'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
  }
  label {
    display: inline-block;
    min-height: 24px;
    padding-left: 32px;
    background: url('../../../assets/img/input_check.svg') no-repeat 0 0;
    cursor: pointer;
    span {
      display: inline-block;
      margin-top: 4px;
      line-height: 16px;
    }
  }
  input[type='checkbox']:checked + label {
    background: url('../../../assets/img/input_check_checked.svg') no-repeat 0 0;
    font-weight: $font-weight-bold;
  }
  input[type='checkbox']:disabled + label {
    background: url('../../../assets/img/input_check_disabled.svg') no-repeat 0
      0;
    color: #aaa;
  }
  &.type02 {
    position: relative;
    width: 24px;
    height: 24px;
    label {
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 24px;
      font-size: 0;
      color: transparent;
    }
  }
  &.reverse {
    label {
      padding-left: 0;
      padding-right: 32px;
      background-position: 100% 0;
    }
    input[type='checkbox']:checked + label {
      background-position: 100% 0;
      font-weight: normal;
    }
    input[type='checkbox']:disabled + label {
      background-position: 100% 0;
      color: #aaa;
    }
  }
}

.searchOptionArea {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: #efefef;
  .flex {
    align-items: center;
  }
  .innerBox {
    display: flex;
    align-items: center;
    width: 100%;
    & + .innerBox {
      margin-left: 65px;
      @include pc-size-full {
        margin-left: 4%;
      }
    }
  }
  .title {
    margin: 0 16px;
    font-weight: $font-weight-regular;
    white-space: noWrap;
  }
  .status {
    flex: 1;
  }
  .option_detail {
    flex: auto;
    .inputWrap {
      width: 100%;
    }
    .btn {
      width: 80px;
    }
  }
  .rowBox {
    display: flex;
    float: left;
    @include pc-size-full {
      width: 100%;
      flex: 1;
    }
  }
  &.row {
    flex-direction: column;
    align-items: flex-start;
    .flex {
      width: 100%;
      .inputWrap {
        width: 100%;
        flex: 1;
        margin-left: 10px;
      }
    }
    .flex + .flex {
      margin-top: 10px;
    }
  }
  .btn {
    min-width: 76px !important;
  }
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type03 {
      min-width: auto;
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
  }
}

.selectbox {
  background: $color-white;
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
    &.sizeS {
      min-width: 160px;
    }
  }
}

.fullWidth {
  width: 100%;
}

.table_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.excelPosition {
  margin-left: 1500px;
}

.pointer_cursor {
  cursor: pointer;
}
