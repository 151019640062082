// datepicker 영역
.select_date {
  display: flex;
  .period {
    display: flex;
    margin-right: 20px;
    border-radius: 8px;
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 84px;
      height: 48px;
      border-top: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
      border-left: 1px solid $color-border;
      background: $color-white;
    }
    li:first-child {
      .btn {
        border-radius: 8px 0 0 8px;
      }
    }
    li:last-child {
      .btn {
        border-right: 1px solid $color-border;
        border-radius: 0 8px 8px 0;
      }
    }
    li {
      &.on {
        .btn {
          border-color: $color-primary;
          color: $color-primary;
        }
        & + li {
          .btn {
            border-left-color: $color-primary;
          }
        }
      }
    }
  }
  .date {
    display: flex;
    align-items: center;
    .title {
      margin-right: 10px;
    }
    .input_text {
      width: 120px;
    }
    .datepick {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      background: url('../img/ico_calendar.svg') no-repeat;
    }
    .dash {
      display: inline-block;
      margin: 0 10px;
    }
  }
  .input_wrap {
    margin-left: 40px;
  }
  &.time {
    .datepick + .input_text {
      margin-left: 10px;
    }
    .timepick {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-left: 10px;
      background: url('../img/ico_time.svg') no-repeat;
    }
  }
  .timepicker {
    margin-left: 20px;
  }
}

.additionalInfo {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  font-size: #222;
  font-weight: $font-weight-medium;
  font-size: $font-small;
  span {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
}

//input table
.input_table_wrap {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #ccc;
  .areaTitle {
    margin-top: 0;
  }
  &.first {
    border-top: none;
    margin-top: 0;
  }
  &.last {
    border-bottom: 2px solid #222;
  }
}
.input_table {
  tbody {
    th {
      padding-top: 18px;
      vertical-align: top;
      text-align: left;
    }
    td {
      height: 58px;
      padding: 5px 0;
      vertical-align: middle;
      &.top {
        padding-top: 18px;
        vertical-align: top;
      }
    }
  }
}

.input_table02 {
  tbody {
    th {
      padding-top: 18px;
      vertical-align: top;
      text-align: left;
      font-weight: $font-weight-regular;
    }
    td {
      height: 58px;
      padding: 5px 0;
      vertical-align: middle;
      &.top {
        padding-top: 18px;
        vertical-align: top;
      }
    }
  }
}

//datatable
.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .box_left {
    display: flex;
    align-items: center;
    .total_count {
      span {
        color: $color-pink;
      }
    }
    .selectbox {
      width: 72px;
      margin-left: 16px;
    }
  }
  .box_right {
    display: flex;
    align-items: center;
    gap: 10px;
    .btn {
      min-width: 80px;
    }
    .note {
      margin-top: 0;
    }
  }
}
.dataTable01 {
  position: relative;
  margin-top: 22px;
  thead {
    tr:first-child {
      th {
        border-top: 2px solid $color-default;
      }
    }
    th {
      border-top: 1px solid #ccc;
      padding: 14px 0 14px;
      text-align: center;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    & + tbody {
      tr:first-child {
        td {
          border-top: 1px solid #ccc;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      border-bottom: 1px solid #ccc;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    tr {
      &:first-child {
        td {
          border-top: 2px solid $color-default;
        }
      }
    }
  }
  &.type02 {
    // 가로스크롤
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    table {
      min-width: 120%;
      max-width: 10000px;
    }
  }
  &.type03 {
    // 세로스크롤
    overflow-x: hidden;
    overflow-y: auto;
    height: 250px;
    border-top: 2px solid #000;
    thead {
      position: sticky;
      left: 0;
      top: -2px;
      z-index: 1;
      background: #f9f9fa;
      th {
        border-top: none !important;
        text-align: center;
      }
    }
  }
  &.type04 {
    // 전체 border
    thead {
      th {
        border-left: 1px solid #ddd;
      }
      tr:first-child {
        th:first-child {
          border-left: none;
        }
      }
    }
    tbody {
      th {
        border-left: 1px solid #ddd;
        &:first-child {
          border-left: none;
        }
      }
      td {
        border-left: 1px solid #ddd;
        &:first-child {
          border-left: none;
        }
      }
    }
    .bg_type01 {
      background: #efefef;
    }
    .bg_type02 {
      background: #ddd;
    }
    .bg_type03 {
      background: #eedd9b;
    }
    .bg_type04 {
      background: #d5e8ee;
    }
    .bg_type05 {
      background: #bff2cd;
    }
  }
  .totalCount {
    position: absolute;
    right: 0;
    top: -36px;
    margin-top: 0;
  }
  .ico_reply {
    position: relative;
    display: inline-block;
    height: 20px;
    min-width: 20px;
    padding: 0 3px;
    margin-left: 5px;
    border-radius: 10px;
    line-height: 20px;
    background: #00aab7;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    color: #fff;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url('../img/ico_reply.svg') no-repeat;
    }
  }
  .xmp {
    white-space: pre-line;
    @include textEllipsisClamp(5);
  }
  .progressNum {
    span {
      text-decoration: underline;
    }
  }
  .failed {
    color: #f65f8d;
  }
}

.table_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

//info List
.infoList {
  li {
    font-size: $font-small;
  }
}

// 발송률 progress
.sendProgressWrapper {
  display: flex;
  width: 200px;
  margin: 0 50px;
  align-items: center;
  .sendProgress {
    margin-right: 20px;
  }
}
.sendProgress {
  width: 100%;
  height: 24px;
  border-radius: 100px;
  background: #efefef;
  .guage {
    height: 100%;
    border-radius: 100px;
    background: #aaa;
    font-size: 0;
    color: transparent;
    &.point {
      background: #f65f8d;
    }
  }
  &.type02 {
    width: 100px;
    height: 12px;
    margin: 8px auto 0;
    .guage {
      background: #f65f8d;
    }
  }
}

/* 권한관리 */
.searchOptionArea {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: #efefef;
  .flex {
    align-items: center;
  }
  .innerBox {
    display: flex;
    align-items: center;
    & + .innerBox {
      margin-left: 65px;
      @include pc-size-full {
        margin-left: 4%;
      }
    }
  }
  .title {
    margin-right: 16px;
    font-weight: $font-weight-regular;
    white-space: noWrap;
  }
  .status {
    flex: 1;
  }
  .option_detail {
    flex: auto;
    .inputWrap {
      width: 100%;
    }
    .btn {
      width: 80px;
    }
  }
  .rowBox {
    display: flex;
    float: left;
    @include pc-size-full {
      width: 100%;
      flex: 1;
    }
  }
  &.row {
    flex-direction: column;
    align-items: flex-start;
    .flex {
      width: 100%;
      .inputWrap {
        width: 100%;
        flex: 1;
        margin-left: 10px;
      }
    }
    .flex + .flex {
      margin-top: 10px;
    }
  }
  .btn {
    min-width: 76px !important;
  }
}

// .in_popup_input_table {
//   width: 554px;
// }

/*** 메인 ***/
.main {
  .date_nav {
    margin-top: 24px;
    .nav {
      width: 24px;
      height: 24px;
      background: url('../img/arr_left.svg') no-repeat;
      vertical-align: middle;
      font-size: 0;
      color: transparent;
      &.next {
        transform: rotate(180deg);
      }
    }
    .date {
      display: inline-block;
      margin: 0 12px;
      vertical-align: middle;
    }
    .btn_calendar {
      width: 24px;
      height: 24px;
      margin-left: 12px;
      background: url('../img/ico_calendar.svg') no-repeat 50% 50% / 24px;
      font-size: 0;
      color: transparent;
      vertical-align: middle;
    }
  }

  .status_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 20px;
    border-bottom: 2px solid #222;
    .value {
      display: flex;
      dl {
        display: flex;
        align-items: baseline;
        font-weight: $font-weight-bold;
        dt {
          margin-right: 20px;
        }
        dd {
          strong {
            font-size: 48px;
          }
        }
        & + dl {
          position: relative;
          margin-left: 84px;
          padding-left: 84px;
          &:before {
            position: absolute;
            left: 0;
            top: 14px;
            content: '';
            display: block;
            width: 1px;
            height: 40px;
            background: #ddd;
          }
        }
        &.lge {
          dd {
            color: #f65f8d;
          }
        }
        &.partner {
          dd {
            color: #00aab7;
          }
        }
      }
    }
  }

  .partner_list {
    display: flex;
  }
  .list {
    position: relative;
    overflow: hidden;
    width: calc(100% - 312px);
    margin-left: 20px;
    .swiper-slide {
      width: 340px;
      background: #ebf3f5;
      &:first-child {
        background: #cce5eb;
      }
      dl {
        padding: 24px 20px;
        dt {
          font-weight: $font-weight-bold;
          font-size: $font-large-1;
          & + dd {
            margin-top: 24px;
          }
        }
        dd {
          text-align: right;
        }
      }
    }
    .nav {
      top: 50%;
      z-index: 1;
      width: 24px;
      height: 24px;
      margin-top: -12px;
      background: url('../img/arr_next.svg') no-repeat;
      &.swiper-button-prev {
        margin-left: 12px;
        transform: rotate(180deg);
      }
      &.swiper-button-next {
        margin-right: 12px;
      }
    }
  }

  .main_dashboard {
    margin-top: 32px;
    .flex {
      gap: 20px;
      justify-content: space-between;
      & + .flex {
        margin-top: 20px;
      }
    }
    .detailBox {
      display: flex;
      flex: 1;
      gap: 20px;
      padding: 24px 20px;
      background: #ececec;
      .title_01 {
        width: 16%;
        font-size: 20px;
      }
      .status {
        position: relative;
        flex: 1;
        padding: 20px;
        background: #f5f5f5;
        .new {
          position: absolute;
          right: 20px;
          top: 20px;
          height: 40px;
          padding: 0 16px;
          line-height: 40px;
          border-radius: 20px;
          background: #ececec;
          @include pc-size-l {
            display: inline-block;
            position: static;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
          }
        }
        .detail {
          display: flex;
          align-items: flex-end;
          min-height: 68px;
          margin-top: 20px;
          @include pc-size-l {
            display: block;
          }
          .case {
            color: $color-primary;
            .num {
              font-size: 56px;
              line-height: 0.8em;
            }
          }
          .value {
            margin-left: 32px;
            @include pc-size-l {
              margin-left: 0;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .detailBox02 {
      flex: 1;
      padding: 24px 20px;
      background: #ececec;
      .title_01 {
        font-size: 20px;
      }
      .flex {
        gap: 15%;
      }
      dl {
        display: flex;
        align-items: flex-end;
        flex: 1;
        justify-content: space-between;
        margin-top: 10px;
        dt {
          flex: 1;
          span {
            display: block;
            font-size: 12px;
          }
        }
        dd {
          flex: 1;
          text-align: right;
          color: #00aab7;
          .num {
            font-size: 56px;
            line-height: 0.88em;
          }
        }
      }
    }
  }
  .table_header {
    margin-top: 16px;
  }
}

.release_scope {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  .scope_item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    border-radius: 16px;
    line-height: 32px;
    background: #363636;
    color: $color-white;
    .delete {
      width: 20px;
      height: 20px;
      background: url('../img/btn_delete2.svg') no-repeat;
      font-size: 0;
      color: transparent;
    }
  }
}

.board_contents {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.reply_wrapper {
  margin-top: 25px;
  .reply_wrap {
    .info {
      position: relative;
      display: flex;
      margin-bottom: 16px;
      .date {
        margin-left: 20px;
        color: #767676;
      }
      .delete {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background: url('../img/btn_close.svg') no-repeat;
        font-size: 0;
        color: transparent;
      }
    }
    .cont {
      margin-bottom: 24px;
    }
  }
  .reply_write {
    display: flex;
    align-items: stretch;
    padding-top: 10px;
    border-top: 1px solid $color-border;
    .input_textarea {
      flex: 1;
      padding: 12px 20px;
      background: #efefef;
    }
    .apply {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-right: 20px;
      background: #efefef;
      .input_check {
        margin-bottom: 10px;
      }
    }
  }
}

/* 온보딩 */
.onboarding {
  .popup {
    .article_container {
      overflow: hidden;
      max-width: 1280px;
      text-align: center;
      .txt {
        height: 75px;
        margin-top: 48px;
        font-size: 24px;
        color: #767676;
        span {
          display: block;
          margin-top: 16px;
          font-size: 20px;
          color: $color-default;
        }
      }
      .indicator {
        margin-top: 36px;
      }
      .btn_container4 {
        margin-top: 48px;
      }
    }
  }
}

.swiper-pagination-bullets {
  margin-top: 36px;
  text-align: center;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 8px;
    background: $color-bg-1;
    &.swiper-pagination-bullet-active {
      background: $color-default;
    }
  }
}

/* 상품관리 */
.price_count {
  display: flex;
  align-items: center;
  .input_text {
    width: 120px;
  }
  .title {
    margin: 0 20px 0 48px;
    font-weight: $font-weight-bold;
  }
}

.adjustmentSystem {
  margin-left: 48px;
  padding: 16px 16px 20px;
  background: #efefef;
  .areaTitle2 {
    display: block;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    align-items: center;
    gap: 48px;
    & + .row {
      margin-top: 10px;
    }
  }
}

.statusDashBoard {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .count {
    position: relative;
    width: 21.5%;
    padding: 24px 20px 8px;
    background: #ececec;
    text-align: right;
    .title {
      text-align: left;
      font-weight: $font-weight-bold;
    }
    .value {
      color: $color-primary;
      font-weight: $font-weight-bold;
      span {
        font-size: 56px;
      }
    }
    a.value {
      text-decoration: underline;
    }
    .type {
      position: absolute;
      right: 20px;
      top: 24px;
      display: flex;
      padding: 0 8px;
      border-radius: 12px;
      background: #fff;
      li {
        position: relative;
        margin-left: 5px;
        padding-left: 5px;
        font-size: $font-small;
        &:before {
          position: absolute;
          left: -5px;
          top: 3px;
          content: '';
          display: block;
          width: 1px;
          height: 12px;
          margin: 2px 5px 0;
          background: #ddd;
        }
        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
    &.total {
      background: #ececec url('../img/ico_total.svg') no-repeat 20px
        calc(100% - 25px);
      min-width: 16%;
      flex: 1 0 auto;
    }
    &.sale {
      background: #ececec url('../img/ico_sale.svg') no-repeat 20px
        calc(100% - 25px);
      min-width: 16%;
      flex: 1 0 auto;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    dl {
      flex: 1;
      padding: 24px 20px;
      background-color: #ebf3f5;
      dt {
        font-size: $font-large-1;
      }
      dd {
        position: relative;
        .valueWrap {
          display: flex;
          @include pc-size-full {
            margin-top: 20px;
          }
          .confirm {
            color: #165c61;
            &:before {
              content: '';
              display: inline-block;
              margin: 0 20px;
              width: 1px;
              height: 25px;
              background: #aaa;

              @include pc-size-full {
                margin: 0 5px;
              }
            }
          }
          &.type02 {
            margin-top: 32px;
            .value {
              font-size: 14px;
              .num {
                font-size: 32px;
              }
            }
          }
        }
        .value {
          font-weight: $font-weight-bold;
          color: #00aab7;
          @include pc-size-full {
            font-size: 14px;
          }
          .act {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
            background: $color-act-1;
            @include pc-size-full {
              width: 22px;
              height: 22px;
              vertical-align: text-bottom;
            }
          }
          .num {
            font-size: 56px;
            line-height: 1;
          }
          .co_list_btn {
            position: relative;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-left: 10px;
            background: #fff url('../img/ico_co_list.svg') no-repeat 50% 50%;
            font-size: 0;
            vertical-align: text-bottom;
            color: transparent;
            &:hover {
              .co_list {
                display: block;
              }
            }
          }
          .co_list {
            position: absolute;
            left: 50%;
            top: 47px;
            display: none;
            min-width: 170px;
            padding: 8px 16px;
            background: #fff;
            font-weight: $font-weight-regular;
            font-size: 14px;
            color: #222;
            transform: translateX(-50%);
            &:before {
              position: absolute;
              left: 50%;
              top: -4px;
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              margin-left: -5px;
              background: #fff;
              transform: rotate(45deg);
            }
          }
        }
        .detail {
          position: absolute;
          right: 0px;
          top: 28px;
          display: flex;
          padding: 4px 16px;
          border-radius: 20px;
          background: #fff;
          li {
            position: relative;
            margin-left: 5px;
            padding-left: 5px;
            font-size: $font-small;
            &:before {
              position: absolute;
              left: -5px;
              top: 3px;
              content: '';
              display: block;
              width: 1px;
              height: 12px;
              margin: 2px 5px 0;
              background: #ddd;
            }
            &:first-child {
              margin-left: 0;
              padding-left: 0;
            }
            &:first-child:before {
              display: none;
            }
            span {
              font-weight: $font-weight-bold;
            }
            .ico_arr {
              position: relative;
              top: -2px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../img/arr_right5.svg') no-repeat;
              vertical-align: middle;
            }
          }
        }
        .standard {
          text-align: right;
          font-size: $font-small;
          @include pc-size-full {
            margin-top: 30px;
          }
        }
      }
    }
    &.flow {
      gap: 0;
      dl {
        position: relative;
        display: flex;
        flex: auto;
        align-items: baseline;
        padding-left: 80px;
        @include pc-size-ml {
          padding-left: 40px;
        }
        dt {
          min-width: 50px;
          font-size: 16px;
          font-weight: 700;
          padding-top: 62px;
          background-repeat: no-repeat;
          .note {
            font-weight: $font-weight-medium;
            margin-top: 4px;
          }
        }
        dd {
          margin-left: 20px;
          .detail {
            right: auto;
            left: -25px;
            top: -35px;
          }
        }
        &:before {
          position: absolute;
          left: -15px;
          top: 50%;
          content: '';
          display: block;
          width: 30px;
          height: 24px;
          margin-top: -12px;
          background: url('../img/ico_next.svg') no-repeat;
        }
        &:first-of-type {
          &:before {
            display: none;
          }
        }
      }
      &.shipping {
        dl:nth-child(1) dt {
          background-image: url('../img/ico_new_order.svg');
          background-position: 0 0;
        }
        dl:nth-child(2) dt {
          background-image: url('../img/ico_product_ready.svg');
          background-position: 0 0;
        }
        dl:nth-child(3) dt {
          background-image: url('../img/ico_shipping_ready.svg');
          background-position: 0 0;
        }
        dl:nth-child(4) dt {
          background-image: url('../img/ico_shipping_ing.svg');
          background-position: 0 0;
        }
        dl:nth-child(5) dt {
          background-image: url('../img/ico_shipping_complete.svg');
          background-position: 0 0;
        }
      }
    }
  }
  .delay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    background: #ffe6e6;
    min-width: 16%;
    padding: 0 2.5%;
    dl {
      display: flex;
      align-items: flex-end;
      dt {
        flex: 1 0 auto;
        min-width: 50px;
        padding-top: 62px;
        font-weight: 700;
        font-size: 16px;
        background: url('../img/ico_shipping_delay.svg') no-repeat;
      }
      dd {
        margin-left: 20px;
        font-weight: 700;
        .value {
          color: #f65f8d;
          .num {
            font-size: 56px;
            line-height: 1;
          }
        }
      }
    }
  }
}

/* 쿠폰관리 */
.stepsFlow {
  display: flex;
  justify-content: space-between;
  .steps {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    height: 90px;
    padding-left: 50px;
    background: #eee;
    font-size: $font-large-2;
    color: #999;
    &:after {
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      display: block;
      width: 56px;
      height: 90px;
      background: url(../img/img_steps_normal.svg) no-repeat;
    }
    &:last-child:after {
      display: none;
    }
    &.on {
      background: #00aab7;
      color: #fff;
      &:after {
        background-image: url(../img/img_steps_next_on2.svg);
      }
      &:before {
        position: absolute;
        left: -56px;
        top: 0;
        content: '';
        display: block;
        width: 56px;
        height: 90px;
        background: url(../img/img_steps_next_on.svg) no-repeat;
      }
      &:first-child:before {
        display: none;
      }
      & + .on {
        &:before {
          background-image: url(../img/img_steps_next_on3.svg);
        }
      }
      .badge {
        position: absolute;
        right: 56px;
        top: 28px;
        display: inline-block;
        height: 34px;
        padding: 0 12px;
        line-height: 34px;
        border-radius: 17px;
        background: #fff;
        font-size: $font-medium;
        font-weight: $font-weight-bold;
        color: #00aab7;
      }
    }
  }
  &.type02 {
    .steps {
      padding-left: 1%;
    }
  }
}

.stepGuideText {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
  .btn_container6 {
    position: absolute;
    right: 0;
    top: -6px;
    .btn {
      min-width: 120px;
      width: auto;
    }
  }
  & + .dataTable01 {
    margin-top: 16px;
  }
}
.addCoupon {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  .box {
    flex: 1;
    padding: 16px 20px;
    background: #efefef;
    .flex {
      align-items: center;
    }
    .title {
      margin-right: 20px;
    }
    .btn_add {
      width: 120px;
    }
  }
  .input {
    .input_text {
      flex: 1 1 auto;
      max-width: 320px;
    }
    .btn_add {
      margin-left: 10px;
    }
    .note {
      padding-left: 80px;
    }
  }
  .file {
    position: relative;
    .attatchedFile {
      position: absolute;
      right: 180px;
      top: 20px;
      text-align: right;
      .fileName {
        margin-top: 12px;
        .delete {
          width: 20px;
          height: 20px;
          margin-left: 20px;
          background: url('../img/btn_delete.svg') no-repeat;
          vertical-align: middle;
          font-size: 0;
          color: transparent;
        }
      }
    }
    .btn_add {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }
  .add {
    padding: 0;
    background: none;
    .flex {
      gap: 8px;
      padding-right: 180px;
    }
  }
  .list {
    position: relative;
    .totalCount {
      position: absolute;
      left: 20px;
      top: 42px;
    }
    .addCouponList {
      margin-left: 190px;
    }
  }
  & + .download_form {
    margin-top: 20px;
    padding-left: calc(50% + 10px);
    .btn {
      vertical-align: middle;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-size: $font-small;
    }
  }
}

.stepsNav {
  border-top: 2px solid #222;
  margin-top: 30px;
  padding-top: 16px;
  text-align: right;
}

.totalCount {
  margin-top: 20px;
  text-align: right;
}
.addCouponList {
  overflow-y: auto;
  height: 240px;
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  .delete {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    background: url('../img/btn_delete.svg') no-repeat;
    vertical-align: middle;
    font-size: 0;
    color: transparent;
  }
}
.publishFailed {
  .popup {
    width: 1280px;
  }
}
.verificationStep {
  ul {
    display: flex;
    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      margin-left: 10px;
      padding: 0 34px;
      border: 1px solid #707070;
      border-radius: 24px;
      background: #fff;
      &:before {
        position: absolute;
        left: -11px;
        top: 50%;
        content: '';
        display: block;
        width: 10px;
        height: 1px;
        background: #707070;
      }
      &:first-child {
        margin-left: 0;
        &:before {
          display: none;
        }
      }
      &.on {
        background: #363636;
        border: none;
        color: #fff;
      }
    }
  }
}

.msg_form_wrap {
  display: flex;
  .form_wrap {
    .inner {
      padding-right: 120px;
      @include pc-size-full {
        padding-right: 5%;
      }
    }
    flex: 1;
    padding: 32px;
    background: #efefef;
    .title {
      position: relative;
      span {
        font-size: 14px;
        font-weight: $font-weight-regular;
        color: #767676;
      }
      .btn {
        position: absolute;
        right: 0;
        top: 0;
      }
      &.border {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid $color-border;
      }
    }
    .input_table {
      margin-top: 20px;
    }
    .variable {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;
      li {
        .btn {
          min-width: auto;
          width: 128px;
        }
      }
    }
  }
  .talk_preview {
    display: flex;
    flex: 1;
    align-items: center;
    background: #eef4f6;
    .preview_wrapper {
      width: 360px;
      margin: 0 auto;
      .talk_header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border-radius: 40px 40px 0 0;
        background: #f4ce3c;
        font-size: 20px;
        &:after {
          position: absolute;
          right: 70px;
          top: -18px;
          content: '';
          display: block;
          width: 58px;
          height: 58px;
          background: url(../img/ico_kakaotalk.svg) no-repeat;
        }
      }
      .talk_body {
        padding: 32px 32px 90px 32px;
        border-radius: 0 0 40px 40px;
        background: $color-white;
        white-space: pre-line;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        .btn {
          width: 100%;
          background: #efefef;
          color: $color-default;
        }
      }
      .talk_footer {
        margin-top: 22px;
        text-align: center;
        .refresh {
          display: inline-block;
          padding-left: 25px;
          background: url('../img/ico_refresh.svg') no-repeat;
          font-size: $font-small;
        }
        .btn_container4 {
          margin-top: 20px;
        }
      }
    }
  }
  .lms_preview {
    display: flex;
    flex: 1;
    align-items: center;
    background: #eef4f6;
    .preview_wrapper {
      width: 360px;
      margin: 0 auto;
      .lms_header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border-radius: 40px 40px 0 0;
        background: #00aab7;
        font-size: 20px;
        color: $color-white;
      }
      .lms_body {
        padding: 32px 32px 90px 32px;
        border-radius: 0 0 40px 40px;
        background: $color-white;
        white-space: pre-line;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        .btn {
          width: 100%;
          background: #efefef;
          color: $color-default;
        }
      }
      .lms_footer {
        margin-top: 22px;
        text-align: center;
        .btn_container4 {
          margin-top: 20px;
        }
      }
    }
  }
  .refresh {
    display: inline-block;
    padding-left: 25px;
    background: url('../img/ico_refresh.svg') no-repeat;
    font-size: $font-small;
  }
  &.email {
    flex-direction: column;
    .form_wrap {
      display: flex;
      gap: 240px;
      .inner {
        flex: 1;
        padding: 0;
        & + .inner {
          position: relative;
          &:before {
            position: absolute;
            left: -94px;
            top: 0;
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background: $color-border;
          }
        }
      }
    }
    .email_edit {
      padding: 32px;
      background: #eef4f6;
    }
    .editor {
      margin-top: 10px;
    }
  }
}

.optionInputWrap {
  margin-top: 20px;
  padding: 32px;
  background: #efefef;
}

.drafts {
  display: flex;
  gap: 20px;
  margin-top: 32px;
  .box {
    flex: 1;
  }
  .box_right {
    background: #efefef;
    .textForm {
      padding: 32px;
      .info {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #aaa;
      }
      .text {
        white-space: pre-line;
      }
    }
  }
}
.flowChart {
  margin-top: 15px;
  padding: 20px;
  background: #efefef;
  .title {
    margin-bottom: 16px;
  }
  .img {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}
