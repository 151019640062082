@import '@assets/scss/style.module.scss';

.toggleBox {
  input[type='checkbox'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
  }
  label {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 32px;
    border-radius: 16px;
    background: $color-bg-1;
    cursor: pointer;
    transition: 0.3s;
    &:after {
      position: absolute;
      left: 2px;
      top: 2px;
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: $color-white;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    span {
      font-size: 0;
      color: transparent;
    }
  }
  input[type='checkbox']:checked + label {
    background: $color-default;
    &:after {
      left: 26px;
    }
  }
  input[type='checkbox']:disabled + label {
    background: #f4f4f4;
  }
}
