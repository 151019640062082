@import '@assets/scss/style.module.scss';
.container {
  display: flex;
  align-items: center;
  z-index: 3;

  .title {
    margin: 0 10px;
  }
  .dash {
    display: inline-block;
    margin-left: 40px;
    margin-right: 10px;
  }

  .datePickerContainer {
    display: flex;
  }
  .datePicker {
    width: 122px;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
    color: #222;
  }
  .react-datepicker__day--highlighted-custom-1 {
    background-color: red;
  }
  // Calendar2 css
  .calendarIcon {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }

  .calIcon {
    cursor: pointer;
    left: 115px;
  }
}

.input_wrap {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.input_radio {
  margin-right: 20px;
  input[type='radio'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
  }
  label {
    display: inline-block;
    min-height: 24px;
    padding-left: 32px;
    background: url('../../assets/img/input_radio.svg') no-repeat 0 0;
    cursor: pointer;
    span {
      display: inline-block;
      margin-top: 4px;
      line-height: 16px;
    }
  }
  input[type='radio']:checked + label {
    background: url('../../assets/img/input_radio_checked.svg') no-repeat 0 0;
    font-weight: $font-weight-bold;
  }
  input[type='radio']:disabled + label {
    background: url('../../assets/img/input_radio_disabled.svg') no-repeat 0 0;
    color: #aaa;
  }
  &.single {
    label {
      padding-left: 24px;
    }
  }
}

// type4
.type4 {
  &.calIcon {
    box-sizing: border-box !important;
    width: 48px !important;
    height: 48px !important;
    left: unset !important;
    right: -48px;
    padding: 10px !important;
  }
  &.dash {
    margin-left: 48px;
  }
}

.period {
  display: flex;
  margin-right: 20px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  li {
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 48px;
      box-sizing: border-box;
      border: 1px solid #ddd;
      border-right-style: none;
    }
    &:first-child {
      button {
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-child {
      button {
        border-radius: 0 8px 8px 0;
        border-right-style: solid;
      }
    }

    &.selected {
      button {
        border-color: #00aab7;
        color: #00aab7;
        border-style: solid;
      }

      & + li > button {
        border-left-style: none;
      }
    }
  }
}
