@import '@assets/scss/style.module.scss';

.dataTable01 {
  position: relative;
  margin-top: 22px;
  thead {
    tr:first-child {
      th {
        border-top: 2px solid $color-default;
      }
    }
    th {
      border-top: 1px solid #ccc;
      padding: 14px 0 14px;
      text-align: center;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    & + tbody {
      tr:first-child {
        td {
          border-top: 1px solid #ccc;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      border-bottom: 1px solid #ccc;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    tr {
      &:first-child {
        td {
          border-top: 2px solid $color-default;
        }
      }
    }
  }
  &.type02 {
    // 가로스크롤
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    table {
      min-width: 120%;
      max-width: 10000px;
    }
  }
  &.type03 {
    // 세로스크롤
    overflow-x: hidden;
    overflow-y: auto;
    height: 250px;
    border-top: 2px solid #000;
    thead {
      position: sticky;
      left: 0;
      top: -2px;
      z-index: 1;
      background: #f9f9fa;
      th {
        border-top: none !important;
        text-align: center;
      }
    }
  }
  &.type04 {
    // 전체 border
    thead {
      th {
        border-left: 1px solid #ddd;
      }
      tr:first-child {
        th:first-child {
          border-left: none;
        }
      }
    }
    tbody {
      th {
        border-left: 1px solid #ddd;
        &:first-child {
          border-left: none;
        }
      }
      td {
        border-left: 1px solid #ddd;
        &:first-child {
          border-left: none;
        }
      }
    }
    .bg_type01 {
      background: #efefef;
    }
    .bg_type02 {
      background: #ddd;
    }
    .bg_type03 {
      background: #eedd9b;
    }
    .bg_type04 {
      background: #d5e8ee;
    }
    .bg_type05 {
      background: #bff2cd;
    }
  }
  .totalCount {
    position: absolute;
    right: 0;
    top: -36px;
    margin-top: 0;
  }
  .ico_reply {
    position: relative;
    display: inline-block;
    height: 20px;
    min-width: 20px;
    padding: 0 3px;
    margin-left: 5px;
    border-radius: 10px;
    line-height: 20px;
    background: #00aab7;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    color: #fff;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url('../../../assets/img/ico_reply.svg') no-repeat;
    }
  }
  .xmp {
    white-space: pre-line;
    @include textEllipsisClamp(5);
  }
  .progressNum {
    span {
      text-decoration: underline;
    }
  }
  .failed {
    color: #f65f8d;
  }
}

.sendProgressWrapper {
  display: flex;
  width: 200px;
  margin: 0 50px;
  align-items: center;
  .sendProgress {
    margin-right: 20px;
  }
}
.sendProgress {
  width: 100%;
  height: 24px;
  border-radius: 100px;
  background: #efefef;
  .guage {
    height: 100%;
    border-radius: 100px;
    background: #aaa;
    font-size: 0;
    color: transparent;
    &.point {
      background: #f65f8d;
    }
  }
  &.type02 {
    width: 100px;
    height: 12px;
    margin: 8px auto 0;
    .guage {
      background: #f65f8d;
    }
  }
}
