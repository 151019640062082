@import '@assets/scss/style.module.scss';

.status {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  dl {
    flex: 1;
    padding: 18px 20px;
    background-color: #ebf3f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.pointer {
  cursor: pointer;
}
