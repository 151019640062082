.btn02 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 24px;
  border-radius: 8px;
  color: #222;
}

.type02 {
  padding: 0 12px;
  border: 1px solid #2e7d32;
  color: #2e7d32;
}

.type01 {
  border: 1px solid #555;
}
.type03 {
  padding: 0 12px;
  border: 1px solid #555;
  color: #222;
}
.ico_excel {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
  background: url('../../assets/img/ico_excel.svg') no-repeat;
}
.ico_upload {
  display: inline-block;
  width: 14px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  background: url('../../assets/img/ico_upload.svg') no-repeat;
}

.title {
  font-size: 12px;
}
