@import '@assets/scss/style.module.scss';

.breadcrumbs {
  ul {
    display: flex;
    li {
      font-size: $font-small;
      cursor: pointer;

      &:after {
        content: '>';
        margin: 0 6px;
      }
      &:last-child {
        color: $color-green;

        &:after {
          display: none;
        }
      }
    }
  }
}
