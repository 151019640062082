@import '@assets/scss/style.module.scss';

.progress {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  li {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    border-radius: 24px;
    line-height: 1;
    background: #efefef;
    color: $color-default;
    &:nth-child(1):before {
      content: '01.';
      margin-right: 5px;
    }
    &:nth-child(2):before {
      content: '02.';
      margin-right: 5px;
    }
    &:nth-child(3):before {
      content: '03.';
      margin-right: 5px;
    }
    &:after {
      position: absolute;
      right: -28px;
      top: 13px;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url('../../../../assets/img/arr_right3.svg') no-repeat;
    }
    &:last-child:after {
      display: none;
    }
    &.on {
      background: $color-default;
      color: $color-white;
    }

    &.check {
      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('../../../../assets/img/ico_check.svg') no-repeat;
      }
    }
  }
}
