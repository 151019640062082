@import '@assets/scss/style.module.scss';

.box_right {
  display: flex;
  justify-content: end;
  gap: 10px;
  width: 85%;
}

.searchContainer {
  display: flex;
  gap: 8px;
}

.input_table_wrap {
  padding-top: 20px;
}

.form {
  display: flex;
  gap: 10px;
}
