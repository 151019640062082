@import '@assets/scss/style.module.scss';

.container {
  margin: 20px 0;

  .box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
}
