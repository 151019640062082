@import '@assets/scss/style.module.scss';

.date_nav {
  margin-top: 24px;
}

.box_right {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.ico_reply {
  position: relative;
  display: inline-block;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  margin-left: 5px;
  border-radius: 10px;
  line-height: 20px;
  background: #00aab7;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  color: #fff;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../../../assets/img/ico_reply.svg') no-repeat;
  }
}

.content {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
}
