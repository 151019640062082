@import '@assets/scss/style.module.scss';

.checkbox {
  input[type='checkbox'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
  }

  label {
    display: inline-block;
    min-height: 24px;
    padding-left: 32px;
    background: url('../../assets/img/input_check.svg') no-repeat 0 0;
    cursor: pointer;
    span {
      display: inline-block;
      margin-top: 4px;
      line-height: 16px;
    }
  }

  input[type='checkbox']:checked + label {
    background: url('../../assets/img/input_check_checked.svg') no-repeat 0 0;
    font-weight: $font-weight-bold;
  }
  input[type='checkbox']:disabled + label {
    background: url('../../assets/img/input_check_disabled.svg') no-repeat 0 0;
    color: #aaa;
  }
  &.noLabel {
    label {
      padding-left: 24px;
    }
  }
}
