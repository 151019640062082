@import '@assets/scss/style.module.scss';

#wrap {
  min-width: 1280px;
  &.has-no-header {
    &.registerWrap {
      background: #f9f9fa;
    }
    #container {
      margin-top: 0;
    }
  }
}
#container {
  flex: 1;
  margin-top: 156px;
}
