@import '@assets/scss/style.module.scss';
.contentBox {
  display: flex;
  align-items: center;

  .content {
    margin-right: 20px;
  }

  .contentVertical {
    display: flex;
    gap: 10px;
  }
}

.radioArea {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 15px;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
  text-align: left;
  margin-bottom: 25px;
}

.verticalTableFlex {
  display: flex;
}

.note {
  font-size: 14px;
  color: #767676;
  text-align: left;
}

.marginLeft {
  margin-left: 15px;
}

.fontLeft {
  text-align: left;
}

.marginTop {
  margin-top: 15px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 2px solid #222;
  padding-top: 32px;
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }

    &.type03 {
      background: #555;
      color: $color-white;
    }
  }

  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;
    &.type02 {
      background: #00aab7;
      color: #fff;
    }
  }
}
.searchOptionArea {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: #efefef;
  .flex {
    align-items: center;
  }
  .innerBox {
    display: flex;
    align-items: center;
    & + .innerBox {
      margin-left: 65px;
    }
  }
  .title {
    margin-right: 16px;
    font-weight: $font-weight-regular;
    white-space: noWrap;
  }
  .status {
    flex: 1;
  }
  .option_detail {
    flex: auto;
    .inputWrap {
      width: 100%;
    }
    .btn {
      width: 80px;
    }
  }
  .rowBox {
    display: flex;
    float: left;
  }
  &.row {
    flex-direction: column;
    align-items: flex-start;
    .flex {
      width: 100%;
      .inputWrap {
        width: 100%;
        flex: 1;
        margin-left: 10px;
      }
    }
    .flex + .flex {
      margin-top: 10px;
    }
  }
  .btn {
    min-width: 76px !important;
  }
}

.ico_reply {
  position: relative;
  display: inline-block;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  margin-left: 5px;
  border-radius: 10px;
  line-height: 20px;
  background: #00aab7;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  color: #fff;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/ico_reply.svg') no-repeat;
  }
}

.box_right {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-right: 10px;
  .btn {
    min-width: 80px;
  }
  .note {
    margin-top: 0;
  }
}

th {
  text-align: center;
}

.content {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
}
