.notFoundContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 5vh;

  .highLight {
    color: #00aab7;
  }

  .btnContainer {
    display: flex;
    justify-content: center;
  }
}
