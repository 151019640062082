@import '@assets/scss/style.module.scss';

.pageSummary {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  & + .termsWrap {
    margin-top: 36px;
  }
}
.termsWrap {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1;
  }
  & + .termsWrap {
    margin-top: 27px;
  }
  .termsContents {
    overflow-x: hidden;
    overflow-y: auto;
    height: 104px;
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 8px;
    font-size: $font-small;
    white-space: pre-line;
  }
}
