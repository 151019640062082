@import '@assets/scss/style.module.scss';

.contentBox {
  display: flex;
  align-items: center;

  .content {
    margin-right: 20px;
  }

  .contentVertical {
    display: flex;
    gap: 10px;
  }
}

.inputBox {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.inputWrap.type04 .input_text {
  width: 50%;
}

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
  text-align: left;
  margin-bottom: 25px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 2px solid #222;
  padding-top: 32px;
}

.fontLeft {
  text-align: left;
}

.marginTop {
  margin-top: 15px;
}

.areaTitle {
  margin-top: 32px;
  font-size: $font-large;
}

.reply_container {
  text-align: left;
}

.reply_wrapper {
  margin-top: 25px;
  .reply_wrap {
    .info {
      position: relative;
      display: flex;
      margin-bottom: 16px;
      .date {
        margin-left: 20px;
        color: #767676;
      }
      .delete {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background: url('../../assets/img/btn_close.svg') no-repeat;
        font-size: 0;
        color: transparent;
      }
    }
    .cont {
      margin-bottom: 24px;
    }
  }
}

.reply_write {
  display: flex;
  align-items: stretch;
  padding-top: 10px;
  border-top: 1px solid $color-border;
  .input_textarea {
    flex: 1;
    padding: 12px 20px;
    background: #efefef;
  }
  .apply {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
    background: #efefef;
    .input_check {
      margin-bottom: 10px;
    }
  }
}

.input_textarea {
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #fff;
  font-size: 16px;
  color: #222;
}

.input_check label {
  display: inline-block;
  min-height: 24px;
  padding-left: 32px;
  background: url('../../assets/img/input_check.svg') no-repeat 0 0;
  cursor: pointer;
}

.input_check input[type='checkbox']:checked + label {
  background: url('../../assets/img/input_check_checked.svg') no-repeat 0 0;
  font-weight: 700;
}

.btn {
  vertical-align: middle;
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;
    &.type01 {
      background: #363636;
      color: $color-white;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.noticeMetaDataBody {
  display: flex;
  justify-content: space-between;

  .noticeMetaSubData {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
