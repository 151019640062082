// 실제 사용 되는 common

#wrap {
  min-width: 1280px;
  &.has-no-header {
    #container {
      margin-top: 0;
    }
  }
}
#container {
  flex: 1;
  margin-top: 156px;
}

// box
.text_box1 {
  padding: 14px;
  border-radius: 8px;
  background: #eee;
  border: 1px solid #ddd;
}

// Title
.pageTitle {
  font-size: $font-large-2;
}
.pageTitle2 {
  text-align: center;
  font-size: $font-large-2;
}
.sectionTitle {
  margin-top: 60px;
  font-size: $font-large-1;
}
.sectionTitle02 {
  margin-top: 20px;
  font-size: $font-large-1;
}
.areaTitle {
  margin-top: 32px;
  font-size: $font-large;
}
.areaTitle2 {
  font-size: $font-medium;
  .additionalInfo {
    font-size: $font-small;
    color: #c30036;
  }
  .btn {
    margin-left: 20px;
  }
}

// button container
.btn_container {
  display: flex;
  margin-top: 36px;
  .btn {
    flex: 1;
  }
}
.btn_container2 {
  display: flex;
  gap: 20px;
  margin-top: 36px;
  .btn {
    flex: 0.5;
    & + .btn {
      flex: 1.5;
    }
  }
}
.btn_container3 {
  display: flex;
  gap: 20px;
  margin-top: 36px;
  .btn {
    min-width: 170px !important;
    width: 100%;
  }
}
.btn_container4 {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin-top: 36px;
  .btn {
    width: 180px;
  }
}
.btn_container5 {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;
  margin-top: 32px;
  .btn {
    flex: 1 0 auto;
    width: 180px;
  }
}
.btn_container6 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  .btn {
    width: 178px;
  }
}
.btn_container7 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  .btn {
    width: 180px;
    &.btn02 {
      width: auto;
    }
  }
  &.border {
    border-top: 2px solid #222;
    padding-top: 32px;
  }
}

// selectbox 컨테이너 사이즈

.selectbox {
  &.sizeM {
    width: 243px;
  }
  &.sizeS {
    min-width: 160px;
  }
  &.sizeXS {
    width: 96px;
  }
}

// PhoneNumber 타입 컨테이너 사이즈
.phoneNumberContainer {
  max-width: 437px;
}

/* global */
.fL {
  float: left !important;
}
.fR {
  float: right !important;
}

.txtL {
  text-align: left !important;
}
.txtC {
  text-align: center !important;
}
.txtR {
  text-align: right !important;
}

.vAlignT {
  vertical-align: top;
}
.vAlignM {
  vertical-align: middle;
}

.bgNone {
  background: none !important;
}
.bgImgNone {
  background-image: none !important;
}

/* padding */
.pd0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pt2 {
  padding-top: 2px;
}
.pt5 {
  padding-top: 5px;
}
.pt8 {
  padding-top: 8px;
}
.pt10 {
  padding-top: 10px;
}
.pt12 {
  padding-top: 12px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt25 {
  padding-top: 25px;
}
.pt30 {
  padding-top: 30px;
}
.pt50 {
  padding-top: 50px;
}
.pb2 {
  padding-bottom: 2px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb18 {
  padding-bottom: 18px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb45 {
  padding-bottom: 45px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb100 {
  padding-bottom: 100px;
}

/* margin */
.mg0 {
  margin: 0 !important;
}
.mt0 {
  margin-top: 0;
}
.mt3 {
  margin-top: 3px;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt15 {
  margin-top: 15px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt32 {
  margin-top: 32px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt45 {
  margin-top: 45px;
}
.mt50 {
  margin-top: 50px;
}
.mt80 {
  margin-top: 80px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb60 {
  margin-bottom: 60px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml50 {
  margin-left: 50px;
}
.mr3 {
  margin-right: 3px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}

/* color */
.color-pink {
  color: #f65f8d;
}
.color-red {
  color: #c30036;
}
.color-error {
  color: #ff3723;
}
.color-primary {
  color: #00aab7;
}
.color-default {
  color: #111;
}
.color-grey {
  color: #767676;
}

/* border */
.border-none {
  border: none !important;
}
.border-top-grey01 {
  border: 0;
  border-top: 1px solid #ccc;
}
.border-top-grey02 {
  border: 0;
  border-top: 1px solid #888;
}
.border-color-blue {
  border-color: #0a798f;
}
.border-bottom-grey01 {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
}

/* background-color */
.bg-color-blue {
  background-color: #0a798f;
} /* 배경 블루컬러 */
.bg-color-white {
  background-color: #fff;
}
.bg-color-grey01 {
  background-color: #fbfbfb;
}
.bg-color-grey02 {
  background-color: #f8f8f8;
}
.bg-color-grey03 {
  background-color: #e6e6e6;
}
.bg-color-aqua {
  background-color: rgba(2, 121, 143, 0.1);
}
.bg-color-pink {
  background-color: rgba(218, 15, 71, 0.07);
}
.bg-color-green {
  background-color: #f1f8e9;
}
.bg-color-babypink {
  background-color: #ffebea;
}

/* font-weight */
.font-w400 {
  font-weight: 400;
}
.font-w500 {
  font-weight: 500;
}
.font-w700 {
  font-weight: 700;
}
.font-w900 {
  font-weight: 900;
}

.ff-roboto {
  font-family: 'Roboto', sans-serif;
}
.font-x-small {
  font-size: 12px;
}
.font-small {
  font-size: 14px;
}
.font-medium {
  font-size: 16px;
}
.font-large {
  font-size: 18px;
}
.font-large-1 {
  font-size: 20px;
}
.font-large-2 {
  font-size: 24px;
}
.font-large-3 {
  font-size: 28px;
}
.fs-0 {
  font-size: 0;
}
.pos-relative {
  position: relative;
}

/* flex */
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-1 {
  flex: 1 1 auto;
}
.align-content-center {
  align-items: center;
}
.align-content-start {
  align-items: flex-start;
}
.align-content-end {
  align-items: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.align-center-gap8 {
  align-items: center;
  gap: 8px;
}
.align-center-gap10 {
  align-items: center;
  gap: 10px;
}

.fullWidth {
  width: 100%;
}
