@import '@assets/scss/style.module.scss';

.tabMenu01 {
  .list {
    position: relative;
    .nav {
      position: absolute;
      z-index: 1;
      width: 72px;
      height: 100%;
      font-size: 0;
      color: transparent;
      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid $color-border;
        border-radius: 50%;
        background: #fff url(../../assets/img/arr_right2.svg) no-repeat 5px 50%;
      }
      &.prev {
        left: 0;
        top: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        &:after {
          margin-left: 24px;
          transform: rotate(180deg);
        }
      }
      &.next {
        right: 0;
        top: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 50%
        );
        &:after {
          margin-left: 24px;
        }
      }
    }
    ul {
      display: flex;
      // justify-content: space-between;
      li {
        // flex: 1;
        display: flex;
        .btn {
          position: relative;
          flex: 1;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          padding: 0 24px;
          background: $color-white;
          font-size: $font-large;
          color: $color-gray-3;
          cursor: pointer;
          &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: $color-border;
          }
        }
        &.on {
          .btn {
            font-weight: $font-weight-bold;
            color: $color-default;
            &:after {
              height: 3px;
              background: $color-primary;
            }
          }
        }
      }
    }
  }
  &.type02 {
    .list {
      li {
        .btn {
          height: 60px;
          span {
            position: relative;
            display: inline-flex;
            align-items: center;
            height: 100%;
          }
        }
        &.on {
          .btn {
            border-bottom: 1px solid $color-border;
            &:after {
              display: none;
            }
            &:after {
              position: absolute;
              left: 0;
              bottom: 0;
              content: '';
              display: block;
              width: 100%;
              height: 3px;
              background: $color-primary;
            }
          }
        }
      }
    }
    &.type02-1 {
      .list {
        ul {
          justify-content: flex-start;
        }
        li {
          flex: none;
          .btn {
            height: 26px;
            padding: 0 12px;
            font-size: $font-small;
          }
        }
      }
    }
  }
}

.tabMenu02 {
  .list {
    ul {
      display: flex;
      gap: 12px;
      li {
        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          padding: 0 24px;
          border-radius: 24px;
          font-size: $font-medium;
          background: $color-bg-3;
          color: #000;
        }
        &.on {
          .btn {
            background: $color-default;
            font-weight: $font-weight-bold;
            color: $color-white;
          }
        }
      }
    }
  }
}

.tabMenu03 {
  .list {
    border-bottom: 1px solid #ccc;
    ul {
      display: flex;
      gap: 2px;
      li {
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          min-width: 128px;
          border-radius: 8px 8px 0 0;
          background: #ddd;
          text-align: center;
          cursor: pointer;
        }
        &.on {
          .btn {
            background: #333;
            color: $color-white;
          }
        }
      }
    }
  }
  .cont_area {
    .cont {
      display: none;
    }
  }
}
