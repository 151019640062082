@import '@assets/scss/style.module.scss';

.container {
  position: relative;

  .textArea {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background: $color-white;
    font-size: $font-medium;
    color: $color-default;

    &:focus {
      border-color: $color-default;
    }
    &::placeholder {
      color: #aaa;
    }

    &.required {
      &:after {
        content: '*';
        font-size: inherit;
        color: #be3455;
      }
    }

    &.disabled {
      background: $color-bg-4;
      color: #aaa;
    }

    &:read-only {
      background: $color-bg-4;
      color: #aaa;
    }
    &:read-only:focus {
      border-color: #ddd;
    }
  }

  // .byteCount {
  //   position: absolute;
  //   right: 16px;
  //   bottom: 15px;
  //   z-index: 1;
  //   font-size: $font-small;
  //   color: #aaa;
  // }
}

.byteCount {
  display: flex;
  justify-content: end;
  font-size: $font-small;
  color: #aaa;
}
