.container {
  margin: 20px 0;

  .checkBoxArea {
    display: flex;
    align-items: center;
  }

  .TableCheckBoxArea {
    margin-top: 20px;
    text-align: center;
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 10px 15px;
          background-color: #888;
          color: #fff;
          font-weight: 700;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 5px 15px;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }

  .radioArea {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}
