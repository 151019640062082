@import '@assets/scss/style.module.scss';

.logo {
  display: flex;
  align-items: center;
  font-size: $font-large-1;
  cursor: pointer;

  .logoImg {
    margin-right: 10px;
  }
}
