@import '@assets/scss/style.module.scss';

.container {
  position: relative;

  &.fullWidth {
    width: 100% !important;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background: $color-white;
    font-size: $font-medium;
    color: $color-default;
    &:focus {
      border-color: $color-default;
    }
    &::placeholder {
      color: #aaa;
    }
    &.disabled {
      background: $color-bg-4;
      color: #aaa;
    }

    &.bytePadding {
      padding-right: 120px;
    }
  }

  &.small {
    input[type='text'] {
      height: 32px;
      padding: 0 12px;
    }
  }

  .byteCount {
    position: absolute;
    right: 16px;
    top: 15px;
    z-index: 1;
    // font-size: $font-small;
    color: #aaa;
  }

  .readOnly {
    background: #eee !important;
    color: #aaa !important;
  }

  .readOnly:focus {
    border-color: #ddd !important;
  }

  .error {
    min-height: 20px;
    margin-top: 11px;
    padding-left: 24px;
    background: url('../../assets/img/ico_caution.svg') no-repeat;
    line-height: 20px;
    font-size: 14px;
    color: #ff3723;
    text-align: left;
  }

  .confirmed {
    min-height: 20px;
    margin-top: 11px;
    padding-left: 24px;
    background: url('../../assets/img/ico_confirmed.svg') no-repeat;
    line-height: 20px;
    font-size: 14px;
    color: #76892d;
    text-align: left;
  }
}
