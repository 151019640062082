@import '@assets/scss/style.module.scss';

.button {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;

    &.type01 {
      background: $color-default;
      color: $color-white;
    }
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
    &.type03 {
      // min-width: auto;
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
    &.type04 {
      min-width: 60px;
      height: 32px;
      padding: 0 10px;
      background: #363636;
      color: $color-white;
    }
    &.type05 {
      height: 48px;
      padding: 0 10px;
      border: 1px solid #707070;
      background: $color-white;
      font-weight: $font-weight-medium;
    }
    &.type06 {
      min-width: 60px;
      height: 32px;
      padding: 0 10px;
      background: #666;
      color: $color-white;
    }
  }
  &.btn02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    background: $color-white;
    font-size: 14px;
    color: $color-default;
    &.type01 {
      border: 1px solid #555;
    }
    &.type02 {
      padding: 0 12px;
      border: 1px solid #2e7d32;
      color: #2e7d32;
    }
    &.type03 {
      padding: 0 12px;
      border: 1px solid #555;
      color: $color-default;
    }
    &.type04 {
      padding: 0 12px;
      border: 1px solid #555;
      color: $color-default;
    }
    .ico_excel {
      display: inline-block;
      width: 16px;
      height: 15px;
      margin-right: 4px;
      vertical-align: middle;
      background: url('../../assets/img/ico_excel.svg') no-repeat;
    }
    .ico_upload {
      display: inline-block;
      width: 14px;
      height: 16px;
      margin-right: 4px;
      vertical-align: middle;
      background: url('../../assets/img/ico_upload.svg') no-repeat;
    }
    .ico_download {
      display: inline-block;
      width: 14px;
      height: 16px;
      margin-right: 4px;
      vertical-align: middle;
      background: url('../../assets/img/ico_download.svg') no-repeat;
    }
  }

  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;

    &.type01 {
      background: #363636;
      color: $color-white;
    }
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
    &.type03 {
      border: 1px solid #333;
      background: $color-white;
      color: $color-default;
    }
    &.type04 {
      background: #666;
      color: $color-white;
    }
  }
  &.btn04 {
    height: 43px;
    padding: 0 12px;
    border-radius: 8px;
    font-size: $font-medium;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    &.type01 {
      border: 1px solid $color-default;
    }
  }

  &:disabled {
    background: $color-bg-2 !important;
    color: $color-white;
    cursor: not-allowed;
  }

  &.fullWidth {
    width: 100%;
  }
}

.ico_act {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;
  &.type01 {
    background: $color-act-1;
  }
  &.type02 {
    background: $color-act-2;
  }
  &.type03 {
    background: $color-act-3;
  }
  &.type04 {
    background: $color-act-4;
  }
}
