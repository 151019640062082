@import '@assets/scss/style.module.scss';

.popup_title {
  position: relative;
  display: block;
  width: 100%;
  font-size: $font-large-1;
  color: $color-default;
}

.selectbox {
  background: $color-white;
  &.type01 {
    position: relative;
    min-width: 96px;
    height: 48px;
    border-radius: 8px;
    &.sizeS {
      min-width: 160px;
    }
  }
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type03 {
      min-width: auto;
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
  }
}

.tableContainer {
  margin-top: -73px;
}
