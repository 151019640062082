@import '@assets/scss/style.module.scss';

.searchOptionArea {
  padding: 0px;
}
.refund {
  dl:nth-child(1) dt {
    background-image: url(../../../assets/img/ico_disuse_request.svg);
    background-position: 0 0;
  }
  dl:nth-child(2) dt {
    background-image: url(../../../assets/img/ico_disuse_ing.svg);
    background-position: 0 0;
  }
}

.box_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn {
  vertical-align: middle;

  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    font-size: 14px;
  }
  &.type01 {
    background: #363636;
    color: #fff;
  }
}

.ico_act {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;
  &.type01 {
    background: #ff5b5b;
  }
  &.type02 {
    background: #fe9f3f;
  }
  &.ico_act.type03 {
    background: #50dc75;
  }
}

.popup_title {
  text-align: left;
  position: relative;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #222;
}

.popup_body {
  margin-top: 32px;
  min-width: 550px;
}

.btn_container4 {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin-top: 36px;
}

.btn_container {
  display: contents;
}

.statusDashBoard {
  gap: 45px;
}
