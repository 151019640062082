.main_nodata {
  padding: 60px;
  margin-top: 20px;
  background: #ececec;
  text-align: center;
  .btn_container4 {
    display: flex;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin-top: 36px;
  }
}
