@import '@assets/scss/style.module.scss';

.inputWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}

.dateBox {
  width: 100%;
  display: flex;
  align-items: center;
  .radioBox {
    display: flex;
    align-items: center;
    margin-left: 40px;
    gap: 20px;
  }
}
