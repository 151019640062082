.container {
  &.fullWidth {
    width: 100%;
  }
  .form {
    display: inline-flex;
    width: 100%;
    gap: 8px;
  }
  .button {
    margin-left: 10px;
  }

  .buttonContainer {
    width: 72px;
  }
}
