@import '@assets/scss/style.module.scss';

.detailBoxSmall {
  flex: 1;
  padding: 24px 20px;
  background: #ececec;

  .title_01 {
    font-size: 20px;
  }
  .flex {
    gap: 15%;
  }
  dl {
    display: flex;
    align-items: flex-end;
    flex: 1;
    justify-content: space-between;
    margin-top: 15px;
    dt {
      flex: 1;
      span {
        display: block;
        font-size: 12px;
      }
    }
    dd {
      flex: 1;
      text-align: right;
      color: #00aab7;
      .num {
        font-size: 56px;
        line-height: 0.88em;
        cursor: pointer;
      }
    }
  }

  .ico_act {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    border-radius: 50%;
    &.type01 {
      background: $color-act-1;
    }
    &.type02 {
      background: $color-act-2;
    }
    &.type03 {
      background: $color-act-3;
    }
    &.type04 {
      background: $color-act-4;
    }
  }
}
//승훈추가
.disabledDetailBox {
  opacity: 0.3 !important;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  .type_color_red {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
    color: #c30036;
  }
}
