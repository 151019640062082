@import '@assets/scss/style.module.scss';

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%; /* 화면 높이에 맞게 설정 */
}
/* Table Container */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 36px;
}

/* Table Header */
.headerList {
  padding: 15px;
}

.largeWidth {
  &:nth-child(2) {
    width: 60%;
  }
}

.header {
  border-bottom: 1px solid #ccc;
  padding: 12px 0 16px;
}

.header:first-child {
  border-top: 2px solid #222;
  text-align: center;
}

.headerCell {
  padding: 10px;
  border: 1px solid #444;
}

/* Table Body */
.tbodyList {
  padding: 10px;
  &.largeWidth {
    &:nth-child(2) {
      text-align: left;
    }
  }
}

.tbodyRow {
  text-align: center;
}

.tbodyCell {
  padding: 10px;
  border: 1px solid #ddd;
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.pagination .nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-size: 0;
  color: transparent;
}
.pagination .nav:after {
  content: '';
  display: block;
  width: 14px;
  height: 12px;
}
.pagination .nav.first {
  margin-right: 6px;
}
.pagination .nav.first:after {
  transform: rotate(180deg);
  background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
}
.pagination .nav.prev:after {
  transform: rotate(180deg);
  background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
}
.pagination .nav.next:after {
  background: url('../../assets/img/arr_pagination.svg') no-repeat 50% 50%;
}
.pagination .nav.last {
  margin-left: 6px;
}
.pagination .nav.last:after {
  background: url('../../assets/img/arr_pagination2.svg') no-repeat 50% 50%;
}
.pagination .nav:disabled {
  background: #f4f4f4;
}
.pagination .nav:disabled:after {
  opacity: 0.2;
}
.pagination .order {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 12px;
}
.pagination .order li {
  margin: 0 12px;
}
.pagination .order li button {
  font-size: 14px;
}
.pagination .order li.on button {
  font-weight: 700;
}

.pageIndexInfo {
  margin-left: 15px;
}

.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box_left {
    display: flex;
    align-items: center;
    .total_count {
      span {
        color: #f65f8d;
      }
    }
    .selectbox {
      width: 72px;
      margin-left: 16px;
    }
  }
  .box_right {
    .btn {
      width: 80px;
    }
  }
}

.btn {
  &.btn03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    &.type02 {
      background: #00aab7;
      color: #fff;
    }
  }
}
