@import '@assets/scss/style.module.scss';

.registerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 360px;
  height: calc(100vh - 140px);
  margin: 0 auto;
  &.type02 {
    display: block;
    max-width: 1280px;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-bottom: 60px;
    background: $color-white;
    .register_contents {
      display: block;
      width: 720px;
      margin: 0 auto;
      .pageTitle {
        text-align: center;
        margin-top: 24px;
      }
    }
  }
  .register_link {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    &.single {
      display: block;
      text-align: right;
    }
  }
}

.register_input {
  margin-top: 60px;
}
.register_input2 {
  margin-top: 36px;
}
.register_input3 {
  margin-top: 16px;
}

.register_header {
  display: flex;
  align-items: center;
  padding-left: 60px;
  height: 80px;
  border-bottom: 1px solid $color-border;
  .copyright {
    display: inline-block;
    min-height: 24px;
    line-height: 24px;
    padding-left: 32px;
    background: url('../../../assets/img/logo.svg') no-repeat 0 50% / 24px;
    font-weight: $font-weight-bold;
    color: $color-default;
  }
}
