@import '@assets/scss/style.module.scss';

.registerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.register_input {
  margin-top: 60px;
}

.mb10 {
  margin-bottom: 10px;
}

.btn_container4 {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin-top: 36px;
  .btn {
    min-width: 180px !important;
  }
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type01 {
      background: $color-default;
      color: $color-white;
    }
    &.type02 {
      background: $color-primary;
      color: $color-white;
    }
    &.type03 {
      min-width: auto;
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
  }
}

.inputWrap {
  margin-bottom: 10px;
}
