@import '@assets/scss/style.module.scss';

.cont_area {
  width: 100%;
  .cont {
    width: 100%;
  }
}
.date {
  margin-top: 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
  text-decoration: underline;
}

.messagePreview {
  width: 400px;
  padding: 30px;
  margin-top: 20px;
  white-space: pre-line;
  background: #eef4f6;
}
.dateBox {
  width: 100%;
  display: flex;
  align-items: center;
  .radioBox {
    display: flex;
    align-items: center;
    margin-left: 40px;
    gap: 20px;
  }
}

.dash {
  margin-left: 46px;
  margin-right: 10px;
}
