@import '@assets/scss/style.module.scss';

.container {
  position: relative;

  .date_nav {
    margin-top: 24px;
  }

  .testArea {
    position: absolute;
    top: 0px;
    right: 0px;
    .testList {
      display: flex;
      gap: 10px;
    }
  }

  .main_dashboard {
    margin-top: 32px;
    .flex {
      gap: 20px;
      justify-content: space-between;
      & + .flex {
        margin-top: 20px;
      }
    }
    .detailBox {
      display: flex;
      flex: 1;
      gap: 20px;
      padding: 24px 20px;
      background: #ececec;
      .title_01 {
        width: 16%;
        font-size: 20px;
      }
      .status {
        position: relative;
        flex: 1;
        padding: 20px;
        background: #f5f5f5;
        .new {
          position: absolute;
          right: 20px;
          top: 20px;
          height: 40px;
          padding: 0 16px;
          line-height: 40px;
          border-radius: 20px;
          background: #ececec;
          @include pc-size-l {
            display: inline-block;
            position: static;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
          }
        }
        .detail {
          display: flex;
          align-items: flex-end;
          min-height: 68px;
          margin-top: 20px;
          @include pc-size-l {
            display: block;
          }
          .case {
            color: $color-primary;
            .num {
              font-size: 56px;
              line-height: 0.8em;
            }
          }
          .value {
            margin-left: 32px;
            @include pc-size-l {
              margin-left: 0;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .detailBox02 {
      flex: 1;
      padding: 24px 20px;
      background: #ececec;
      .title_01 {
        font-size: 20px;
      }
      .flex {
        gap: 15%;
      }
      dl {
        display: flex;
        align-items: flex-end;
        flex: 1;
        justify-content: space-between;
        margin-top: 10px;
        dt {
          flex: 1;
          span {
            display: block;
            font-size: 12px;
          }
        }
        dd {
          flex: 1;
          text-align: right;
          color: #00aab7;
          .num {
            font-size: 56px;
            line-height: 0.88em;
          }
        }
      }
    }
  }

  .box_right {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}

.cont_area {
  width: 100%;
  .cont {
    width: 100%;
    display: none;
    .areaTitle {
      margin-top: 32px;
      font-size: 18px;
    }
    .shippingManage {
      .row {
        display: flex;
        padding: 20px;
        margin-top: 20px;
        background: #ececec;
        &.first {
          background: #dedede;
        }
        .titleArea {
          width: 280px;
          dt {
            font-weight: bold;
            font-size: 18px;
            .type_color_red {
              display: block;
              margin-top: 4px;
              font-size: 14px;
              font-weight: normal;
              color: #c30036;
            }
          }
          dd {
            margin-top: 54px;
            font-weight: bold;
            color: #00aab7;
            .num {
              line-height: 1;
              font-size: 48px;
            }
          }
        }

        .link {
          display: block;
          width: 100%;
          height: 100%;
          padding: 24px 20px;
          background: #f5f5f5;
          dl {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .value {
              font-weight: bold;
              color: #00aab7;
              line-height: 1;
              .num {
                font-size: 32px;
              }
            }
            .notConnected {
              position: absolute;
              right: 20px;
              bottom: 24px;
              border-radius: 20px;
              padding: 4px 12px;
              background: #ececec;
            }
            .d_day {
              position: absolute;
              left: 20px;
              bottom: 24px;
              display: flex;
              justify-content: space-between;
              width: calc(100% - 40px);
              padding: 4px 20px;
              border-radius: 20px;
              background: #ececec;
            }
          }
        }
      }
    }
  }
}
