@import '@assets/scss/style.module.scss';

.container {
  .form {
    display: inline-flex;
    width: 100%;
    gap: 8px;
  }
  .buttonContainer {
    width: 72px;
  }
}
