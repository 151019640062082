@import '@assets/scss/style.module.scss';

.input_text input[type='text'],
.input_text input[type='password'] {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
  color: #222;
}

/* 파일 필드 숨기기 */
.attatchedFile input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.pointer {
  cursor: pointer;
}

.stepGuideText {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 19px;
  .btn_container6 {
    position: absolute;
    right: 0;
    top: -6px;
    .btn {
      min-width: 120px;
      width: auto;
    }
  }
  & + .dataTable01 {
    margin-top: 16px;
  }
}

.dataTable01 {
  position: relative;
  margin-top: 22px;
  thead {
    tr:first-child {
      th {
        border-top: 2px solid $color-default;
      }
    }
    th {
      border-top: 1px solid #ccc;
      padding: 14px 0 14px;
      text-align: center;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    & + tbody {
      tr:first-child {
        td {
          border-top: 1px solid #ccc;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      border-bottom: 1px solid #ccc;
      &.bdl {
        border-left: 1px solid #ccc;
      }
    }
    tr {
      &:first-child {
        td {
          border-top: 2px solid $color-default;
        }
      }
    }
  }
  .totalCount {
    position: absolute;
    right: 0;
    top: -36px;
    margin-top: 0;
  }
}
.underLine {
  cursor: pointer;
  text-decoration: underline;
}

.addCoupon {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  .box {
    flex: 1;
    padding: 16px 20px;
    background: #efefef;
    .flex {
      align-items: center;
    }
    .title {
      margin-right: 20px;
    }
    .btn_add {
      width: 120px;
    }
  }
  .input {
    .input_text {
      flex: 1 1 auto;
      max-width: 320px;
    }
    .btn_add {
      margin-left: 10px;
    }
    .note {
      padding-left: 80px;
    }
  }
  .file {
    position: relative;
    .attatchedFile {
      position: absolute;
      right: 180px;
      top: 20px;
      text-align: right;
      .fileName {
        margin-top: 12px;
        .delete {
          width: 20px;
          height: 20px;
          margin-left: 20px;
          background: url('../../../../assets/img/btn_delete.svg') no-repeat;
          vertical-align: middle;
          font-size: 0;
          color: transparent;
        }
      }
    }
    .btn_add {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }
  .add {
    padding: 0;
    background: none;
    .flex {
      gap: 8px;
      padding-right: 180px;
    }
  }
  .list {
    position: relative;
    .totalCount {
      position: absolute;
      left: 20px;
      top: 42px;
    }
    .addCouponList {
      margin-left: 190px;
    }
  }
  & + .download_form {
    margin-top: 20px;
    padding-left: calc(50% + 10px);
    .btn {
      vertical-align: middle;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-size: $font-small;
      margin-left: 10px;
    }
  }
}

.ico_next {
  display: inline-block;
  width: 8px;
  height: 14px;
  margin-left: 10px;
  vertical-align: middle;
  background: url('../../../../assets/img/arr_right4.svg') no-repeat;
}

.btn {
  vertical-align: middle;
  &.btn01 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 48px;
    padding: 0 20px;
    border-radius: 8px;
    text-align: center;
    font-size: $font-small;
    &.type03 {
      min-width: auto;
      padding: 0 14px;
      background: #555;
      color: $color-white;
    }
  }
  &.btn02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    background: $color-white;
    font-size: 14px;
    color: $color-default;
    &.type01 {
      border: 1px solid #555;
    }
  }
}
